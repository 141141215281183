import { Login, RefreshToken } from "@/api/auth";

const PROP_PREFIX = process.env.VUE_APP_PROP_PREFIX;
export const AUTH_TOKEN_KEY = PROP_PREFIX + "authToken";

export async function loginUser(username, password) {
  try {
    if (username !== "" && password !== "") {
      const loginData = await Login({ username, password });

      setAuthToken(loginData ? loginData : {});
      return Promise.resolve(loginData);
    }
  } catch (err) {
    window.$log.error("Caught an error during login:", err);
    return Promise.reject(err);
  }
}

export function logoutUser() {
  window.$log.message("session terminated");
  localStorage.removeItem(AUTH_TOKEN_KEY);
  window.$axios.setAuthToken("");
}

export function setAuthToken(tokenData) {
  const exp = Date.now() + tokenData.expires_in * 1000;
  const nextTokenData = {
    exp,
    access_token: tokenData.access_token,
    refresh_token: tokenData.refresh_token
  };
  localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(nextTokenData));
  window.$axios.setAuthToken(tokenData.access_token);
}

export async function refreshTokenValidation() {
  const rawData = localStorage.getItem(AUTH_TOKEN_KEY);
  if (rawData === null) return null;
  const tokenData = JSON.parse(rawData);

  try {
    const loginData = await RefreshToken({ token: tokenData.refresh_token });
    setAuthToken(loginData ? loginData : {});
    return Promise.resolve(loginData);
  } catch (err) {
    window.$log.error("Caught an error during login:", err);
    return Promise.reject(err);
  }
}

export function getAuthToken(authTokenKey?: string) {
  const rawData = localStorage.getItem(authTokenKey || AUTH_TOKEN_KEY);
  if (rawData === null) return "";
  const data = JSON.parse(rawData);
  if (isTokenExpired(data)) return "";
  return data.access_token;
}

export function isLoggedIn(fromLogin = false) {
  const tokenData = getAuthToken() || "";
  if (tokenData === "") return false;
  if (!!tokenData && !isTokenExpired(tokenData)) {
    const isAuthenticatedByBiometric = localStorage.getItem("biometrics/isUserAuthenticated");
    const shouldUseBiometric = localStorage.getItem("biometrics/shouldUseBiometric");
    if (shouldUseBiometric === "yes") {
      return isAuthenticatedByBiometric === "yes" && !fromLogin;
    }
    // don't care about bimetrics but user is authenticated with login and password
    return true;
  }
  return false;
}

export function expirationTime() {
  const rawData = localStorage.getItem(AUTH_TOKEN_KEY);
  if (!rawData) return "";
  const authData = JSON.parse(rawData);
  const expDate = new Date(authData["exp"]);
  const expTime = Number(expDate) - Number(new Date());
  const secs = Math.trunc(expTime / 1000);
  const mins = Math.trunc(secs / 60);
  const hors = Math.trunc(mins / 60);
  return `${(hors % 24).zeroLeft()}:${(mins % 60).zeroLeft()}`;
}

function isTokenExpired(tokenData) {
  try {
    return Number(tokenData["exp"]) < Date.now();
  } catch {
    return false;
  }
}

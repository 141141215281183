<template>
  <CFlex @click="fecharMenu" cursor="pointer" class="drawer-link">
    <CBox>
      <OmtIcons :name="icon" size="xl" mr="2" />
      <OmtTypography as="p1">
        {{ label }}
      </OmtTypography>
    </CBox>
    <i class="fa fa-angle-right"></i>
  </CFlex>
</template>

<script>
export default {
  name: "DrawerLink",
  computed: {
    baseUrl() {
      return `${document.location.protocol}//${document.location.host}`;
    },
    uri() {
      if (!this.link) return "";
      return this.link;
    },
    internalLink() {
      return this.uri.startsWith("/") || this.uri.startsWith(this.baseUrl);
    }
  },
  props: {
    link: {
      type: String
    },
    icon: {
      type: String
    },
    label: {
      type: String
    }
  },
  methods: {
    fecharMenu() {
      if (this.uri) {
        this.$root.$emit("show-hide-menu");
        if (!this.internalLink || this.uri !== this.$router.currentRoute.path) {
          try {
            if (this.internalLink) {
              this.$router.push(this.uri);
            } else {
              if (this.$route.params.name === this.label) return;

              this.$router.push({
                name: "external.link",
                query: { title: this.label },
                params: { link: this.uri, name: this.label }
              });
            }
          } catch (error) {
            return error;
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.drawer-link {
  margin: 14px 10px 10px 10px !important;
  transition: all 250ms ease;
  padding: 7px 10px 7px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.drawer-link * {
  color: var(--colors-light-primary-gray1);
  font-size: 13px;
}

.drawer-link div {
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.drawer-link:hover {
  border-radius: 8px;
}

.dark .drawer-link {
  border: 1px solid #1f1f1f;
}

.light .drawer-link {
  border: 1px solid white;
}

@media only screen and (min-width: 992px) {
  /* Desktop */
  .light .drawer-link:hover,
  .light .drawer-link:focus {
    border: 1px solid #031432;
  }

  .dark .drawer-link:hover,
  .dark .drawer-link:focus {
    border: 1px solid white;
  }
}

.drawer-link > *,
.fa-bars {
  cursor: pointer;
  transition: opacity 0.5s ease;
}

.drawer-link:hover,
.fa-bars:hover {
  opacity: 0.6;
}
</style>

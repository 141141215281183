<template>
  <omint-page-content>
    <CFlex :flex-direction="'column'">
      <OmtRow mb="24px">
        <OmtTypography as="h1-bold"> Informações de contato</OmtTypography>
      </OmtRow>

      <OmtRow mb="24px">
        <OmtTypography as="p1">
          <b> Confira se seus dados estão corretos.</b> É importante mantê-los atualizados para o recebimento de
          comunicados e documentos.
        </OmtTypography>
      </OmtRow>

      <OmtRow mb="24px">
        <MenuItem
          icon="home"
          title="Endereço Residencial"
          :description="`
          ${address.TipoLogradouro ? address.TipoLogradouro : ''} 
          ${address.Logradouro ? address.Logradouro : ''} 
          ${address.Numero ? 'Número ' + address.Numero : ''} 
          ${address.Bairro ? 'Bairro ' + address.Bairro : ''} 
          `"
          @change="handle('address')"
          :disabled="!titular"
          :changeStatus="changeStatus.address"
        />
      </OmtRow>

      <OmtRow mb="24px">
        <MenuItem
          icon="envelope"
          title="E-mail"
          :description="!!email ? `Email Cadastrado: ${email}` : 'Nenhum email cadastrado, clique para cadastrar'"
          @change="handle('email')"
          :changeStatus="changeStatus.emails"
        />
      </OmtRow>

      <OmtRow mb="24px">
        <MenuItem
          icon="phone"
          title="Telefone de contato"
          :description="
            !!tel
              ? `Telefone Cadastrado: ${tel.replace('+55 ', '')}`
              : 'Nenhum telefone cadastrado, clique para cadastrar'
          "
          @change="handle('tel')"
          :changeStatus="changeStatus.phones"
        />
      </OmtRow>

      <FixedButtonRow>
        <OmtButton
          :w="['100%', '308px !important']"
          :disabled="existChangeRequest || !hasNewChanges"
          @click="verifyIdentity"
        >
          Salvar alterações
        </OmtButton>
      </FixedButtonRow>
    </CFlex>
  </omint-page-content>
</template>

<script>
import MenuItem from "@/components/Organisms/MyContacts/MenuItem.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import OmtAppPageContent from "@/components/Atoms/omt-app-page-content.vue";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";

export default {
  components: {
    OmtAppPageContent,
    MenuItem,
    FixedButtonRow
  },
  mounted() {
    this.$root.$emit("change-title-label", "Minhas Informações");
  },
  methods: {
    ...mapActions("myContacts", ["validadeChanges"]),
    handle(type) {
      const types = {
        address: () => this.$router.push("/meus-contatos-endereco"),
        email: () => this.$router.push("/meus-contatos-email"),
        tel: () => this.$router.push("/meus-contatos-tel")
      };
      if (type in types) types[type]();
    },
    async verifyIdentity() {
      try {
        this.$root.$emit("show-loading");
        const validacaoAlteracao = await this.validadeChanges();
        if (validacaoAlteracao?.MsgValidacao) {
          this.$toast({
            description: validacaoAlteracao.MsgValidacao,
            status: "error",
            duration: 10000,
            position: "bottom-right"
          });
          return;
        }
      } finally {
        this.$root.$emit("hide-loading");
      }
      this.$router.push({
        path: "/meus-contatos/identity-verification"
      });
    }
  },
  computed: {
    ...mapState({
      titular: state => state.saude.titular,
      address: state => state.myContacts.newAddress || state.myContacts.resumeContacts?.Endereco || {},
      tel: state => {
        if (state.myContacts.newPhones?.length) {
          return state.myContacts.newPhones[0].Numero;
        } else if (state.myContacts.resumeContacts?.Telefones?.length) {
          return state.myContacts.resumeContacts.Telefones[0].Numero;
        }

        return "Nenhum cadastrado";
      },
      email: state => state.myContacts.newEmails?.Pessoal || state.myContacts.resumeContacts?.Emails?.Pessoal || "",
      existChangeRequest: state => state.myContacts.existChangeRequest,
      changeStatus: state => {
        const waitApprovalLabel = "Atualização em andamento";
        const waitAuthenticateLabel = "Aguardando salvar alterações";

        function createStatus(contact) {
          return {
            existChangeRequest: state.myContacts.existChangeRequest ? true : !!state.myContacts[contact],
            label: state.myContacts.existChangeRequest
              ? waitApprovalLabel
              : state.myContacts[contact]
              ? waitAuthenticateLabel
              : ""
          };
        }
        return {
          address: createStatus("newAddress"),
          emails: createStatus("newEmails"),
          phones: createStatus("newPhones")
        };
      }
    }),
    ...mapGetters("myContacts", ["hasNewChanges"])
  }
};
</script>

<style scoped></style>

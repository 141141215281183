<template>
  <div class="blog-posts">
    <OmtSwiper
      v-if="activeBlogs.length > 0"
      :navigation="showNavigation"
      :navigationSize="3"
      :size="activeBlogs.length"
      v-chakra
      v-bind="$attrs"
      @slide-atual="atualPost = Number($event)"
      :ml="atualPost == 0 && ['16px', '0']"
      :class="atualPost > 0 && atualPost === activeBlogs.length - 1 ? 'margin-finish-carousel' : ''"
    >
      <OmtSwiperSlide v-for="(post, id) in activeBlogs" :key="id" maxWidth="330px" @click="openBlogPost(post)">
        <OmtCard p="0" maxWidth="330px" min-height="253px">
          <CImage :src="post.thumb" borderRadius="8px 8px 0px 0px" w="100%" h="160px" object-fit="cover" />
          <CBox p="16px" min-height="120px">
            <CBox d="inline-flex" w="100%" mb="6px">
              <CBox w="80%">
                <OmtTypography as="p1">{{ post.category }}</OmtTypography>
              </CBox>
              <CBox w="auto">
                <CFlex>
                  <OmtTypography as="p1">{{ post.date }}</OmtTypography>
                </CFlex>
              </CBox>
            </CBox>
            <CBox>
              <OmtTypography as="h2-bold">{{ post.title }}</OmtTypography>
            </CBox>
          </CBox>
        </OmtCard>
      </OmtSwiperSlide>
    </OmtSwiper>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Blog from "@/components/Organisms/Blog";
import { mapState, mapGetters } from "vuex";

export default {
  name: "HomeBlogPosts",
  props: {
    posts: {
      type: Array,
      default: []
    }
  },
  data: () => ({
    atualPost: 0
  }),
  computed: {
    ...mapState("saude", ["vinculo"]),
    ...mapGetters("biometrics", ["onNative"]),
    isMobile() {
      const android = navigator.userAgent.match(/Android/i);
      const iPhone = navigator.userAgent.match(/iPhone|iPad|iPod/i);
      const windowsPhone = navigator.userAgent.match(/IEMobile/i);
      return android || iPhone || windowsPhone;
    },
    activeBlogs() {
      if (!this.posts || this.posts.length === 0) return Blog;

      return Blog.filter(post => this.posts.some(_post => _post.Pagina === post.id));
    },
    showNavigation() {
      if (this.isMobile) return true;
      return this.activeBlogs.length > 3;
    }
  },
  methods: {
    async openBlogPost(post) {
      if ("externalLink" in post) {
        let link = post.externalLink;
        if (typeof post.externalLink !== "string") {
          const externalLink = post.externalLink({ titulo: this.vinculo });
          link = this.isMobile ? externalLink.mobile : externalLink.desktop;
        }

        if (this.onNative) {
          const yesOrNo = await Swal.fire({
            title: "Aviso",
            text: "Você será redirecionado para um site externo. Deseja continuar?",
            confirmButtonText: "Continuar",
            cancelButtonText: "Cancelar",
            showCancelButton: true
          });
          if (!yesOrNo.value) return;
        }

        window.open(link, "_blank");
      } else {
        this.$router.push({
          name: "blog",
          params: {
            id: post.id
          }
        });
      }
    }
  }
};
</script>

<template>
  <CBox>
    <OmtGrid v-if="!numberContacted">
      <OmtRow mb="36px">
        <OmtTypography as="h1-bold" align="center">
          Sua solicitação será processada após a confirmação do Token
        </OmtTypography>
      </OmtRow>

      <OmtRow mb="24px">
        <OmtTypography as="p1" align="center"> Selecione um celular: </OmtTypography>
      </OmtRow>

      <OmtRow mb="24px" v-if="phonesOptions.length">
        <center>
          <CRadioGroup v-model="phone">
            <CRadio :value="phone.value" cursor="pointer" v-for="(phone, index) in phonesOptions" :key="index">
              <OmtTypography as="p1"> {{ phone.label }} </OmtTypography>
            </CRadio>
          </CRadioGroup>
        </center>
      </OmtRow>

      <OmtRow
        :mobileStart="2"
        :mobileEnd="3"
        :desktopStart="5"
        :desktopEnd="8"
        :mt="['120px', '']"
        :position="['absolute', 'inherit']"
        :bottom="['80px', 'auto']"
        :width="['calc(100% - 32px)', '100%']"
      >
        <OmtButton variant="solid" width="100%" :disabled="!phone && phones && phones.length" @click="enviarToken">
          Enviar
        </OmtButton>
      </OmtRow>
    </OmtGrid>

    <OmtGrid v-else>
      <OmtRow mb="96px" v-if="numberContacted">
        <OmtTypography as="h1-bold" align="center"> {{ getTextInfo() }} </OmtTypography>
      </OmtRow>

      <OmtRow :desktopStart="5" :desktopEnd="8" mb="18px" justify-content="center">
        <CInput
          max-width="200px"
          :value="token"
          maxlength="8"
          @input="onTokenInput($event)"
          text-align="center"
          variant="flushed"
          v-mask="'####'"
          letter-spacing="14px"
          font-size="26px"
          placeholder="0 0 0 0"
        />
      </OmtRow>
      <OmtRow mb="24px" v-if="counter > 0 && counter < 31 && !tokenValido">
        <OmtTypography as="p2" color="var(--colors-light-primary-gray2)" align="center">
          Reenviar código em 30 segundos <br />
          {{ counter }} segundos
        </OmtTypography>
      </OmtRow>

      <OmtRow
        :mobileStart="2"
        :mobileEnd="3"
        :desktopStart="5"
        :desktopEnd="8"
        :mt="['120px', '']"
        :position="['absolute', 'inherit']"
        :bottom="['80px', 'auto']"
        :width="['calc(100% - 32px)', '100%']"
      >
        <OmtButton
          variant="solid"
          width="100%"
          :disabled="counter > 0 && !canSentTokenAgain && !tokenValido"
          @click="sentToken"
        >
          {{ tentativas === 0 ? "Enviar" : "Reenviar" }}
          Codigo
        </OmtButton>
      </OmtRow>
    </OmtGrid>
  </CBox>
</template>

<script>
import mixin from "@/components/Organisms/TokenModal/mixin";

export default {
  mixins: [mixin],
  data() {
    return {
      interval: null,
      counter: 0,
      canSentTokenAgain: false
    };
  },
  methods: {
    getTextInfo() {
      if (this.numberContacted && this.emailContacted) {
        return `Informe o código enviado para ${this.numberContacted} e para o e-mail ${this.emailContacted}`;
      } else if (this.numberContacted) {
        return `Informe o código enviado para ${this.numberContacted}`;
      } else {
        return `Informe o código enviado para o e-mail ${this.numberContacted}`;
      }
    },
    async sentToken() {
      await this.validarToken();
      if (this.autenticacao && this.tokenValido) {
        this.$emit("success", this.autenticacao);
        return;
      }
      this.startInterval();
      this.canSentTokenAgain = false;
    },
    startInterval() {
      this.interval = setInterval(() => {
        if (this.counter < 31) {
          this.counter += 1;
        } else {
          clearInterval(this.interval);
          this.counter = 0;
          this.canSentTokenAgain = true;
        }
      }, 1000);
    }
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
};
</script>

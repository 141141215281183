<template>
  <CBox :bg="['none', 'var(--colors-light-primary-light-gray)']" minHeight="100vh" width="100vw" position="sticky">
    <OmtLayoutContainer>
      <template v-if="hydrated">
        <OmtRow :mb="['26px', '32px']">
          <OmtTypography as="h1-bold"> O que deseja encontrar? </OmtTypography>
        </OmtRow>
        <OmtRow mb="16px">
          <OmtTypography as="h2-bold"> Busque por nome </OmtTypography>
        </OmtRow>
        <OmtRow :mb="['26px', '42px']">
          <OmtInput
            variant="filled"
            placeholder="Digite o nome do profissional ou clínica"
            icon-right="search"
            :iconRightClick="onSearch"
            v-model="inputText"
            v-on:keydown.enter.prevent="onSearch"
          />
        </OmtRow>
      </template>
      <template v-else>
        <OmtRow mb="16px">
          <OmtSelect placeholder="Selecione um plano" @change="e => setPlano(e)" mb="24px" :initialValue="plano">
            <option v-for="(p, index) in planos" :key="`${p.Codigo}-${index}`" :value="p.Codigo">
              {{ p.Codigo }}
            </option>
          </OmtSelect>
        </OmtRow>
        <OmtRow mb="16px">
          <OmtSelect placeholder="Ou selecione uma rede" @change="e => setRede(e)" mb="24px" :initialValue="rede">
            <option v-for="(r, index) in redes" :key="`${r.Codigo}-${index}`" :value="r.Codigo">
              {{ r.Descricao }}
            </option>
          </OmtSelect>
        </OmtRow>
      </template>

      <OmtRow>
        <div v-if="loaded">
          <OmtTile
            v-for="(tile, index) in filteredGroups"
            :key="index"
            @click="onTileClick(tile)"
            cursor="pointer"
            :icon="tile.icon"
          >
            <template v-slot:lead>
              <CBox
                v-if="tile.badge"
                mr="8px"
                bg="var(--colors-light-secondary-gold)"
                px="6px"
                py="2px"
                border-radius="12px"
              >
                <OmtTypography as="p2" color="white">
                  {{ tile.badge }}
                </OmtTypography>
              </CBox>
            </template>
            <OmtTypography as="h2-semi-bold"> {{ tile.title }} </OmtTypography>
          </OmtTile>
        </div>
      </OmtRow>

      <OmtRow
        v-if="loaded && hydrated"
        @click="setDeactivated"
        :desktopStart="5"
        :desktopEnd="8"
        mt="44px"
        cursor="pointer"
      >
        <OmtTypography as="p1" text-decoration="underline" text-align="center">
          Consultar credenciados <span v-show="deactivated">ativos</span><span v-show="!deactivated">inativos</span>
        </OmtTypography>
      </OmtRow>
      <Onboard v-if="shouldOpenOnboard" onboard="affiliate-search-onboard" />
    </OmtLayoutContainer>
  </CBox>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Onboard, { onboards } from "@/components/Organisms/Onboard";

export default {
  name: "AffiliateHome",
  components: {
    Onboard
  },
  data() {
    return {
      initialOnboardOptin: false,
      inputText: "",
      loaded: false
    };
  },
  computed: {
    ...mapGetters("saude", ["onboardWasAlreadyClosed"]),
    ...mapState("saude", ["hydrated"]),
    ...mapState("affiliate", [
      "groups",
      "plano",
      "planos",
      "rede",
      "redes",
      "favorites",
      "latestViews",
      "deactivated",
      "title",
      "allowAffiliateIndication"
    ]),
    isMobile() {
      const android = navigator.userAgent.match(/Android/i);
      const iPhone = navigator.userAgent.match(/iPhone|iPad|iPod/i);
      const windowsPhone = navigator.userAgent.match(/IEMobile/i);
      return android || iPhone || windowsPhone;
    },
    initialOnboardWasAlreadyClosed() {
      return this.onboardWasAlreadyClosed("affiliate-search-onboard");
    },
    shouldOpenOnboard() {
      return this.isMobile && this.initialOnboardOptin && !this.initialOnboardWasAlreadyClosed;
    },
    hydratedGroups() {
      return [
        {
          validation: !this.deactivated && this.allowAffiliateIndication,
          title: "Solicitação de Indicação Médica",
          linkHandle: () => this.$router.push({ path: "/indicacao-de-credenciados" }),
          icon: "lightbulb",
          badge: "NOVO"
        },
        {
          validation: !this.deactivated && this.favorites.length,
          title: "Meus Favoritos",
          linkHandle: this.goToFavorites,
          icon: "heart"
        },
        {
          validation: !this.deactivated && this.latestViews.length,
          title: "Minhas Últimas visualizações",
          linkHandle: this.goToLastViews,
          icon: "eye"
        }
      ].filter(tile => tile.validation);
    },
    filteredGroups() {
      const tiles = this.hydratedGroups;
      const groups = this.groups.map(g => ({
        title: this.capitalizeWords(g.DescricaoTipoAtendimento),
        icon: this.getIconName(g.TipoView),
        ...g
      }));

      return [...tiles, ...groups];
    }
  },
  methods: {
    ...mapMutations("affiliate", [
      "SET_LOCATION_TYPE",
      "SET_DEACTIVATED",
      "SET_TIPO_ATENDIMENTO",
      "SET_REDE",
      "SET_PLANO",
      "SET_GROUP"
    ]),
    ...mapActions("affiliate", ["fetchGroups", "fetchMatchesByText", "fetchFavorites", "fetchLatestViews", "reset"]),
    ...mapActions("saude", ["getOnboardOptin"]),
    async onSearch() {
      if (this.inputText.length < 3) return;
      await this.$root.$emit("show-loading");
      await this.reset();
      const founds = await this.fetchMatchesByText(this.inputText).catch(() => -1);
      await this.$root.$emit("hide-loading");
      if (founds > 0) {
        this.SET_LOCATION_TYPE("Busca por texto");
        await this.$router.push({ name: "affiliate.docs" });
      } else {
        Swal.fire({
          title: "Aviso",
          text: `Nenhum prestador localizado para o termo de busca informado: ${this.inputText}.`,
          confirmButtonText: "Ok",
          timer: 30000,
          timerProgressBar: true
        });
      }
    },
    async goToFavorites() {
      await this.reset();
      let founds = this.favorites.length;
      if (founds === 0) {
        await this.$root.$emit("show-loading");
        founds = await this.fetchFavorites();
        await this.$root.$emit("hide-loading");
      }
      if (founds > 0) {
        this.SET_LOCATION_TYPE("Favoritos");
        await this.$router.push({ name: "affiliate.docs" });
      } else {
        this.$toast({
          description: "Você ainda não marcou nenhum prestador como favorito.",
          status: "info",
          duration: 30000,
          position: "top"
        });
      }
    },
    async goToLastViews() {
      await this.reset();
      let founds = this.latestViews.length;
      if (founds === 0) {
        await this.$root.$emit("show-loading");
        founds = await this.fetchLatestViews();
        await this.$root.$emit("hide-loading");
      }
      if (founds > 0) {
        this.SET_LOCATION_TYPE("Últimas visualizações");
        await this.$router.push({ name: "affiliate.docs" });
      } else {
        this.$toast({
          description: "Nenhum registro de visualização de prestador foi localizado.",
          status: "info",
          duration: 30000,
          position: "top"
        });
      }
    },
    setDeactivated() {
      this.SET_DEACTIVATED(!this.deactivated);
      this.setTitle();
    },
    setTitle() {
      this.$root.$emit("change-title-label", this.title);
    },
    setPlano(e) {
      this.SET_REDE("");
      this.SET_PLANO(e);
      this.fetchGroups();
    },
    setRede(e) {
      this.SET_REDE(e);
      this.SET_PLANO("");
      this.fetchGroups();
    },
    onTileClick(item) {
      if ("linkHandle" in item) {
        item.linkHandle();
        return;
      }

      const { CodigoRede, TipoView, DescricaoTipoAtendimento } = item;
      this.SET_REDE(CodigoRede);
      this.SET_TIPO_ATENDIMENTO(TipoView);
      this.SET_GROUP(DescricaoTipoAtendimento);
      this.$router.push({ name: "affiliate.specs" });
    },
    getIconName(TipoView) {
      switch (TipoView) {
        case "covid":
          return "virus";
        case "prontsoc":
          return "truck-medical";
        case "mediclin":
          return "user-doctor";
        case "laborato":
          return "flask";
        case "pshospital":
          return "baby-carriage";
        case "hospital":
          return "hospital";
        case "dentclin":
          return "tooth";
        case "terapia":
          return "clipboard-list-check";
        default:
          return "hospital";
      }
    },
    capitalizeWords(inputString) {
      return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
    },
    normalize(value) {
      return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
  },
  async mounted() {
    try {
      // Busca credenciada
      this.$root.$emit("change-back-route", "/");
      await this.setTitle();
      await this.$root.$emit("show-loading");
      if (this.favorites.length === 0) {
        this.fetchFavorites();
      }
      if (this.latestViews.length === 0) {
        this.fetchLatestViews();
      }
      await this.reset();
      if (this.initialOnboardWasAlreadyClosed) return;
      const pendingOptin = await this.getOnboardOptin({ template: onboards["affiliate-search-onboard"].template });

      this.initialOnboardOptin = !!pendingOptin?.Texto;
    } finally {
      this.loaded = true;
      await this.$root.$emit("hide-loading");
    }
  },
  destroyed() {
    this.$root.$emit("change-back-route", null);
  }
};
</script>

<template>
  <omint-page-content v-if="task">
    <resouce-modal v-if="exibirModalPermissao && shouldRequestResources" />
    <h1 class="title">Detalhes do agendamento</h1>
    <BlockList>
      <div class="block-box">
        <h2 class="subtitle">{{ task.Assunto }}</h2>
        <div class="info-box" v-if="task.Beneficiario">
          <i class="fas fa-user"></i>
          <div class="info-row">
            <p>{{ task.Beneficiario }}</p>
            <small>Associado do atendimento</small>
          </div>
        </div>
        <div class="info-box">
          <i class="fas fa-user"></i>
          <div class="info-row">
            <p>{{ task.Staff }}</p>
            <small>{{ task.Especialidade }}</small>
          </div>
        </div>
        <div class="info-box">
          <i class="fas fa-map-marker-alt" v-if="task.Local"></i>
          <div class="info-row">
            <p>{{ task.Local }}</p>
            <small>{{ task.Endereco }}</small>
          </div>
        </div>

        <div class="info-box">
          <div class="info-box day-box">
            <i class="far fa-calendar"></i>
            <div class="info-row">
              <p>{{ task.DisMesAgendamentoExtenso }}</p>
              <small>{{ task.DiaSemanaAgendamento }}</small>
            </div>
          </div>

          <div class="info-box hour-box">
            <i class="far fa-clock"></i>
            <div class="info-row">
              <p>{{ task.Hora }}</p>
              <small>{{ " " }}</small>
            </div>
          </div>
        </div>
        <div class="buttons-box">
          <omt-button v-if="task.PodeCancelar" :onClick="onCancelClick"> Cancelar </omt-button>
          <omt-button v-if="task.PodeReagendar" :onClick="onReagendarClick"> Reagendar </omt-button>
          <omt-button v-if="podeIngressar" :onClick="onIngressarClick"> Ingressar </omt-button>
        </div>
      </div>
    </BlockList>

    <BlockList v-if="localTips.length > 0">
      <div class="hint-box">
        <div class="info-box">
          <img :src="baseUrl + '/images/omint/alert.svg'" />
          <h3 class="dicas">{{ " " }} {{ localType }}</h3>
        </div>
        <ul>
          <li v-for="(t, idx) in localTips" :key="idx">{{ t }}</li>
        </ul>
      </div>
    </BlockList>
  </omint-page-content>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import BlockList from "@/components/Molecules/BlockList";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ResouceModal from "@/components/Organisms/DrOmint/ResourceModal";

export default {
  name: "Agendamento",

  components: {
    BlockList,
    ResouceModal
  },
  data() {
    return {
      task: null,
      exibirModalPermissao: false
    };
  },
  computed: {
    ...mapGetters("saude", ["baseUrl", "defaultRoute"]),
    ...mapState("biometrics", ["camera", "microfone"]),
    ...mapGetters("biometrics", ["onNative"]),
    v25() {
      return Number.parseInt(localStorage.getItem("native_version")) >= 25;
    },
    shouldRequestResources() {
      return this.v25 && this.onNative && (this.camera !== "granted" || this.microfone !== "granted");
    },
    exibirBeneficiario() {
      const logado = this.vinculo?.substring(8, 10) ?? "00";
      return logado !== this.task?.SqBeneficiario;
    },
    localType() {
      if (this.task.TituloDica) return this.task.TituloDica;
      return this.task.Teleatendimento ? "Dicas para o teleatendimento" : "Dicas para o atendimento";
    },
    localTips() {
      if (this.task.Dicas) return this.task.Dicas;
      return this.task.Teleatendimento
        ? [
            "É necessário que o acesso a sua câmera e microfone estejam liberados.",
            "Procure um local com privacidade e sem ruídos."
          ]
        : ["Chegue ao local indicado com 30 minutos de antecedência."];
    },
    podeIngressar() {
      return this.task?.Situacao === 1;
    },
    chamado() {
      return this.$route.query.chamado ? Number(this.$route.query.chamado) : 0;
    }
  },
  methods: {
    ...mapActions("tasks", ["featchTaskDetail", "cancelTask"]),
    ...mapActions("biometrics", ["checkPermissionForResource"]),
    onCancelClick() {
      if (!this.task?.PodeCancelar) return;
      Swal.fire({
        text: "Tem certeza que quer cancelar o agendamento?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Sim",
        denyButtonText: "Não"
      }).then(result => {
        if (result.isConfirmed) {
          this.cancelarTarefa();
        }
      });
    },
    async cancelarTarefa() {
      if (!this.task?.PodeCancelar) return;
      try {
        await this.$root.$emit("show-loading");
        const sucess = await this.cancelTask({ chamado: this.chamado });
        if (sucess) {
          Swal.fire({
            icon: "success",
            text: "Agendamento cancelado com sucesso!",
            confirmButtonText: "OK"
          }).then(async () => {
            this.$router.push(this.defaultRoute);
          });
        }
      } finally {
        await this.$root.$emit("hide-loading");
      }
    },
    onReagendarClick() {
      this.$router.push({
        path: "/reagendar-equipe",
        query: {
          chamado: this.chamado
        }
      });
    },
    limparTimer() {
      if (this.timer !== null) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    agendarConsultaPeriodica() {
      // atualiza a cada minuto
      this.verificarHorarioTask();
      if (this.task.Situacao === 2 || this.task.Situacao === 3) {
        this.limparTimer();
        this.timer = setInterval(() => this.verificarHorarioTask(), 60000);
      }
    },
    onIngressarClick() {
      if (!this.podeIngressar) return;
      if (this.shouldRequestResources) {
        this.exibirModalPermissao = this.shouldRequestResources;
        return;
      }
      this.$router.push({
        name: "tasks.precall",
        params: {
          chamado: this.chamado
        }
      });
    },
    async verificarHorarioTask() {
      const task = await this.featchTaskDetail({ chamado: this.chamado });
      if (!task || (task.Situacao !== 2 && task.Situacao !== 3)) {
        this.limparTimer();
        if (!task) return;
      }
      if (this.task?.Situacao !== task.Situacao) {
        this.task = task;
      }
      if (task.Situacao === 1) {
        Swal.fire({
          text: "Ingressar no atendimento?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Sim",
          denyButtonText: "Não"
        }).then(result => {
          if (result.isConfirmed) {
            this.onIngressarClick();
          }
        });
      }
    }
  },
  watch: {
    shouldRequestResources(value) {
      if (!value) this.exibirModalPermissao = value;
    }
  },
  beforeDestroy() {
    this.limparTimer();
  },
  async created() {
    this.checkPermissionForResource("camera");
    this.checkPermissionForResource("microfone");
  },
  async mounted() {
    try {
      if (this.chamado === 0) {
        return;
      }
      await this.$root.$emit("show-loading");
      this.task = await this.featchTaskDetail({ chamado: this.chamado });
      if (!this.task) {
        return;
      }
      if (this.task.Situacao === 2 || this.task.Situacao === 3) {
        const secs = new Date().getSeconds();
        this.timer = setTimeout(() => this.agendarConsultaPeriodica(), 1000 * (60 - secs));
      }
    } catch (error) {
      window.$log.error("Erro ao carregar a tarefa", error);
    } finally {
      if (this.task) {
        await this.$root.$emit("hide-loading");
      } else {
        this.$router.push(this.defaultRoute);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.block-list + .block-list {
  margin-top: 16px;
}
.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 24px;
}
.subtitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 16px;
}
.dicas {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 0px;
}
.block-box {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 5px 20px 0px 20px;
  i {
    padding-top: 5px;
    padding-right: 10px;
  }
  img {
    padding-top: 15px;
    padding-right: 10px;
    height: 16px;
  }
  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin: 0px;
    padding: 0px;
  }
  small {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
  }
  .day-box {
    width: 100%;
  }
  .hour-box {
    width: 100%;
  }
  .button-box {
    width: 100%;
  }
  .buttons-box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    button {
      margin: 0px 10px 20px 10px;
      min-width: 40%;
      max-height: 42px;
      min-height: 42px;
    }
  }
  .info-box,
  .info-row {
    display: flex;
    justify-content: flex-start;
  }
  .info-row {
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 16px;
  }
  .info-box {
    flex-wrap: nowrap;
    flex-direction: row;
  }
}
.big-font-size {
  .title {
    font-size: 20px;
    line-height: 30px;
  }
  .subtitle {
    font-size: 20px;
  }
  .dicas {
    font-size: 18px;
  }
  .block-box {
    img {
      height: 32px;
    }
    p {
      font-size: 18px;
    }
    small {
      font-size: 13px;
    }
    ul {
      font-size: 15px;
    }
    button {
      min-width: 90%;
      max-height: inherit;
      min-height: inherit;
    }
  }
}
.light {
  .block-box {
    button {
      border: 1px solid #68788c;
    }
  }
  .title {
    color: var(--colors-light-primary-gray1);
  }
  .subtitle,
  .dicas {
    color: var(--colors-light-primary-gray1);
  }
}
.dark {
  .block-box {
    button {
      border: 1px solid #f0f0f0;
    }
  }
  .title {
    color: #f0f0f0;
  }
  .subtitle,
  .dicas {
    color: #f0f0f0;
  }
}
.hint-box {
  display: grid;
  grid-template-rows: 20px 1fr;
  padding: 16px 20px;
  grid-row-gap: 16px;

  .info-box {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .info-box h3 {
    margin: 0;
  }

  ul {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    padding: 0 0 0 20px;
  }
}
@media only screen and (min-width: 992px) {
  .title {
    margin-bottom: 0px;
  }
  .hint-box {
    margin-top: 0px;
  }
  .block-box {
    margin-top: 0px;
    .buttons-box {
      justify-content: flex-start;
      button {
        min-width: unset;
      }
    }
    .day-box,
    .hour-box {
      width: max(15%, 100px);
    }
  }
}
</style>

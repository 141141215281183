<template>
  <OmtGrid>
    <OmtRow>
      <OmtTypography as="h1-bold" mb="24px"> Informações da autorização</OmtTypography>
    </OmtRow>
    <OmtRow>
      <OmtTypography as="p1" mb="24px"> Beneficiário que realizará o procedimento</OmtTypography>
    </OmtRow>
    <OmtRow>
      <OmtSelect
        placeholder="Selecione o beneficiário"
        @change="e => (selectedBeneficiario = e)"
        :initialValue="selectedBeneficiario"
        mb="24px"
      >
        <option v-for="(vinculo, index) in beneficiaries" :key="index" :value="vinculo.IdBeneficiario">
          {{ vinculo.Nome }}
        </option>
      </OmtSelect>
    </OmtRow>

    <OmtRow>
      <OmtTypography as="p1" mb="24px">Tipo do evento</OmtTypography>
    </OmtRow>
    <OmtRow mb="32px" :desktopStart="1" :desktopEnd="6">
      <OmtButton
        :disabled="!hasMedicCredentials"
        height="40px"
        fontSize="14px"
        :variant="selectedEvento === 'Medicina' ? 'solid' : 'outline'"
        @click="selectedEvento = 'Medicina'"
        >Medicina</OmtButton
      >
    </OmtRow>
    <OmtRow mb="32px" :desktopStart="7" :desktopEnd="12">
      <OmtButton
        :disabled="!hasOdontoCredentials"
        height="40px"
        fontSize="14px"
        :variant="selectedEvento === 'Odontologia' ? 'solid' : 'outline'"
        @click="selectedEvento = 'Odontologia'"
        >Odontologia</OmtButton
      >
    </OmtRow>

    <OmtRow v-if="selectedEvento">
      <CFlex>
        <OmtTypography as="p1" mb="6px">Data do Procedimento</OmtTypography>
        <c-tooltip :label="rangeDateEvent">
          <OmtButton size="xs" variant="ghost" height="18px">
            <c-icon name="info" />
          </OmtButton>
        </c-tooltip>
      </CFlex>
    </OmtRow>
    <OmtRow :desktopEnd="2" :mobileEnd="2" mb="5px" v-if="selectedEvento" ali>
      <CFlex style="gap: 10px" align-items="center" v-if="isMobile">
        <OmtTypography as="p1">
          {{ dateEvento ? convertFormatDate(dateEvento) : "--/--/----" }}
        </OmtTypography>
        <omt-button :on-click="openCalendar" class="button-icon-calendar">
          <FontAwesomeIcon icon="fa-light fa-calendar" size="xs" />
        </omt-button>
      </CFlex>
      <OmtInput
        type="date"
        mb="32px"
        v-model="dateEvento"
        v-on:change="validateDateEvento"
        :min="convertFormatDate(this.minDateEvento.toLocaleDateString())"
        :max="convertFormatDate(this.maxDateEvento.toLocaleDateString())"
        @keydown="e => e.preventDefault()"
        v-else
      />
    </OmtRow>
    <OmtRow v-if="isMobile">
      <CDivider mt="5px" mb="16px" />
    </OmtRow>
    <OmtRow v-show="visibility_calendar && selectedEvento" mb="32px">
      <CFlex :justify-content="'center'" width="100%" z-index="1">
        <v-date-picker
          ref="picker"
          @dayclick="onChooseDate"
          v-model="dateCalendar"
          popover-visibility="focus"
          :min-date="minDateEvento"
          :max-date="maxDateEvento"
        >
        </v-date-picker>
      </CFlex>
    </OmtRow>
    <OmtRow :desktopStart="1" :desktopEnd="2" v-if="selectedEvento"> </OmtRow>
    <OmtRow v-if="msgErrorDateEvento" mb="32px" w="100%">
      <OmtTypography as="p1" color="error"> {{ msgErrorDateEvento }} </OmtTypography>
    </OmtRow>

    <OmtRow v-if="selectedEvento">
      <CBox mb="32px">
        <OmtTypography as="p1" mb="6px">Local do Procedimento</OmtTypography>
        <OmtInput type="text" maxlength="100" v-model="localEvento" />
        <OmtTypography as="p2" style="text-align: right"> {{ localEvento.length }}/100 </OmtTypography>
      </CBox>
    </OmtRow>

    <OmtRow mb="64px">
      <CBox bg="var(--colors-light-primary-light-gray)" p="16px" borderRadius="8px">
        <CFlex mb="16px">
          <OmtIcons name="circle-exclamation" mr="16px" />
          <OmtTypography as="h2-bold"> Evite atrasos na liberação da sua autorização </OmtTypography>
        </CFlex>
        <OmtTypography as="p1" mb="16px">
          Para que sua autorização seja liberada, confira abaixo as <b>informações obrigatórias</b> que devem ser
          enviadas: <br
        /></OmtTypography>

        <OmtTypography as="p1"> <b>Pedido médico/odontológico:</b></OmtTypography>
        <omt-li>Nome completo e legível do paciente</omt-li>
        <omt-li>Diagnóstico descrito ou codificado - CID 10</omt-li>
        <omt-li>Descrição completa do procedimento</omt-li>
        <omt-li>
          Dados do profissional (nome completo, assinatura, número de registro no conselho regional da classe -
          CRM/CRO);
        </omt-li>
        <omt-li>Data da realização;</omt-li>
        <omt-li>Local e Unidade da realização do evento.</omt-li>
      </CBox>
    </OmtRow>
    <OmtRow
      justifyContent="center"
      :position="['fixed', 'relative']"
      :bottom="['66px', 'unset']"
      left="0"
      :p="['16px']"
      w="100%"
      background-color="white"
    >
      <OmtButton
        pt="16px"
        pb="16px"
        fontSize="14px"
        :w="['100%', '50% !important']"
        @click="submit()"
        mx="auto"
        :disabled="!canSubmit"
      >
        Continuar
      </OmtButton>
    </OmtRow>
  </OmtGrid>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { CTooltip, CIcon } from "@chakra-ui/vue";
export default {
  components: {
    FontAwesomeIcon,
    CTooltip,
    CIcon
  },
  data() {
    return {
      selectedBeneficiario: "",
      selectedEvento: "",
      dateEvento: "",
      localEvento: "",
      minDateEvento: "",
      maxDateEvento: "",
      msgErrorDateEvento: "",
      hasValidDateEvento: false,
      visibility_calendar: false,
      dateCalendar: new Date()
    };
  },
  methods: {
    ...mapMutations("auths", ["SET_NEW_AUTH"]),
    ...mapActions("auths", ["initBeneficiaries"]),
    validateDateEvento() {
      if (!this.dateEvento) {
        this.msgErrorDateEvento = "";
        this.hasValidDateEvento = false;
        return;
      }
      const day = Number(this.dateEvento.substring(8, 10));
      const month = Number(this.dateEvento.substring(5, 7));
      const year = Number(this.dateEvento.substring(0, 4));
      const dateEvento = new Date(year, month - 1, day);
      if (year < 1000 || year > 9999) {
        this.msgErrorDateEvento = "Ano inválido";
        this.hasValidDateEvento = false;
        return;
      }
      if (dateEvento < this.minDateEvento) {
        this.msgErrorDateEvento = "A data do procedimento deve ser maior que a data de hoje";
        this.hasValidDateEvento = false;
        return;
      }
      if (dateEvento > this.maxDateEvento) {
        this.msgErrorDateEvento = `A data do evento deve estar entre
        ${this.minDateEvento.toLocaleDateString()}
           e ${this.maxDateEvento.toLocaleDateString()}`;
        this.hasValidDateEvento = false;
        return;
      }
      this.msgErrorDateEvento = "";
      this.hasValidDateEvento = true;
    },
    async submit() {
      this.SET_NEW_AUTH({
        ...this.newAuth,
        selectedEvento: this.selectedEvento,
        selectedBeneficiario: this.selectedBeneficiario,
        dateEvento: this.dateEvento,
        localEvento: this.localEvento
      });
      this.$router.push({
        name: "auths.attachments"
      });
    },
    convertFormatDate(date) {
      if (String(date).includes("-")) {
        const partsDate = String(date).split("-");
        const year = partsDate[0];
        const mount = partsDate[1];
        const day = partsDate[2];
        return `${day}/${mount}/${year}`;
      }

      if (String(date).split("/")) {
        const partsDate = String(date).split("/");
        const day = partsDate[0];
        const mount = partsDate[1];
        const year = partsDate[2];
        return `${year}-${mount}-${day}`;
      }
    },
    onChooseDate(date) {
      if (date.date >= this.minDateEvento && date.date <= this.maxDateEvento) {
        this.dateEvento = date.id;
        this.visibility_calendar = false;
        this.hasValidDateEvento = true;
      }
    },
    openCalendar() {
      this.visibility_calendar = true;
      this.$nextTick(() => this.$refs.picker.$el.focus());
    }
  },
  computed: {
    ...mapState("auths", ["newAuth", "beneficiaries"]),
    ...mapState("saude", ["hasMedicCredentials", "hasOdontoCredentials"]),
    canSubmit() {
      return (
        !!this.selectedBeneficiario &&
        !!this.selectedEvento &&
        !!this.dateEvento &&
        !!this.localEvento &&
        this.hasValidDateEvento
      );
    },
    rangeDateEvent() {
      return `A data do evento deve estar entre 
      ${this.minDateEvento.toLocaleDateString()}
         e ${this.maxDateEvento.toLocaleDateString()}`;
    },
    isMobile() {
      const android = navigator.userAgent.match(/Android/i);
      const iPhone = navigator.userAgent.match(/iPhone|iPad|iPod/i);
      const windowsPhone = navigator.userAgent.match(/IEMobile/i);
      const isMobile = android || iPhone || windowsPhone;

      return isMobile;
    }
  },
  async mounted() {
    this.$root.$emit("hide-loading");
    await this.initBeneficiaries();
    const hoje = new Date();
    const minDate = new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate());
    minDate.setDate(minDate.getDate() + 1);
    this.minDateEvento = minDate;
    const maxDate = new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate());
    maxDate.setDate(maxDate.getDate() + 30);
    this.maxDateEvento = maxDate;

    this.selectedEvento = this.newAuth?.selectedEvento ?? "";
    this.selectedBeneficiario = this.newAuth?.selectedBeneficiario ?? "";
    this.dateEvento = this.newAuth?.dateEvento ?? "";
    this.localEvento = this.newAuth?.localEvento ?? "";
    this.validateDateEvento();
  }
};
</script>

<style lang="scss" scoped>
.button-icon-calendar {
  padding: 0 !important;
  min-width: auto !important;
  min-height: auto !important;
}
</style>

<template>
  <CBox>
    <OmtTypography as="h1-bold" mb="32px"> Seleção da vacina </OmtTypography>

    <OmtTypography as="h3-semi-bold" mb="32px"> Selecione a vacina para emissão do voucher. </OmtTypography>

    <OmtRow v-for="(option, index) in vaccineTypes" :key="index">
      <CBox :mb="index !== vaccineTypes.length - 1 ? '24px' : '0px'">
        <CFlex justify="space-between" align-items="center" cursor="pointer">
          <OmtRadio @click="onTypeChange" :value="option.value" :isChecked="vaccineType === option.value">
            <OmtTypography as="p1"> {{ option.label }} </OmtTypography>
          </OmtRadio>
        </CFlex>
      </CBox>
    </OmtRow>

    <FixedButtonRow>
      <OmtButton :w="['100%', '308px !important']" @click="onNextClick" :disabled="!vaccineType"> Continuar </OmtButton>
    </FixedButtonRow>
  </CBox>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";

export default {
  name: "VoucherVaccineTypes",
  components: {
    FixedButtonRow
  },
  data: {
    return: {
      initialType: ""
    }
  },
  computed: {
    ...mapState("voucher", ["vaccineTypes", "vaccineType"])
  },
  methods: {
    ...mapActions("voucher", ["fetchVaccineTypes"]),
    ...mapMutations("voucher", ["SET_VACCINE_STEP", "SET_VACCINE_TYPE", "SET_VACCINE_BENEFICIARIES"]),
    onTypeChange(value) {
      this.SET_VACCINE_TYPE(value);
    },
    async onNextClick() {
      if (this.initialType !== this.vaccineType) {
        this.SET_VACCINE_BENEFICIARIES([]);
      }
      this.SET_VACCINE_STEP(2);
      this.$router.push({
        name: "voucher.vaccine.beneficiaries"
      });
    }
  },
  async mounted() {
    this.$root.$emit("show-loading");
    try {
      this.SET_VACCINE_STEP(1);
      this.initialType = this.vaccineType;
      await this.fetchVaccineTypes();
      if (this.vaccineTypes.length === 1) {
        this.onTypeChange(this.vaccineTypes[0].value);
      }
    } catch (error) {
      window.$log.error("Não foi possível carregar os tipos de vacina", error);
    } finally {
      this.$root.$emit("hide-loading");
    }
  }
};
</script>
<style scoped></style>

<template>
  <OmtInput variant="outline" v-model="token" placeholder="0 0 0 0 0 0 0 0 0" type="number" />
</template>

<script>
export default {
  props: {
    value: String
  },
  computed: {
    token: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    }
  }
};
</script>

<style scoped>
::v-deep input {
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  letter-spacing: 2px;
}

::v-deep input::placeholder {
  color: var(--colors-light-secondary-divider);
}
</style>

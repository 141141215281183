<template>
  <div :id="'switch_' + id" class="switch-box" @click.prevent="changeValue()">
    <div class="switch-slot">
      <label v-if="label && label !== ''" class="switch-label">{{ label }}</label>
      <slot />
    </div>

    <label class="switch">
      <input type="checkbox" :id="id" v-model="check" :disabled="disabled" />
      <span :class="sliderClass"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "SwitchCheckBox",
  props: ["id", "checked", "rounded", "onChange", "disabled", "label"],
  data() {
    return {
      check: !!this.checked
    };
  },
  computed: {
    sliderClass() {
      return this.rounded ? "slider round" : "slider";
    }
  },
  watch: {
    check: function (val, oldVal) {
      if (val !== oldVal) {
        this.onChange(val);
      }
    }
  },
  methods: {
    changeValue() {
      this.check = !this.check;
    }
  }
};
</script>

<style scoped>
.switch-box {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin: 20px 0;
}

.switch-slot {
  display: grid;
  align-items: center;
}

.switch {
  margin: auto 0;
  display: inline-block;
  position: relative;
  height: 34px;
  flex-basis: 70px;
  flex-grow: 0;
  flex-shrink: 0;
}

.switch-label {
  display: flex;
  align-items: center;
  min-height: 34px;
  flex-basis: calc(100% - 70px);
  flex-grow: 0;
  flex-shrink: 0;
}

.dark .switch-label {
  color: #f0f0f0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  width: 60px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 5px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #102a46;
}

input:disabled + .slider {
  background-color: #ccc;
}

input:checked + .slider:before {
  transform: translateX(28px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>

<template>
  <div class="task-list">
    <OmtSwiper
      v-if="taskList.length > 0"
      :navigation="true"
      v-bind="$attrs"
      :size="taskList.length"
      @slide-atual="taskSelected = Number($event)"
      :ml="['16px', '0']"
      :class="taskSelected === taskList.length - 1 ? 'margin-finish-carousel' : ''"
    >
      <OmtSwiperSlide v-for="(item, id) of taskList" :key="id" maxWidth="330px">
        <OmtCard
          maxWidth="330px"
          min-height="253px"
          :border-top="`8px solid ${obterCorSituacao(item.Situacao)}`"
          @onClick="irParaDetalhes(item.Chamado)"
        >
          <div w="100%">
            <CBox p="2">
              <CBox d="inline-flex" w="100%" mb="3">
                <CBox w="auto">
                  <OmtTypography as="h1-bold" fontSize="24" mr="3">{{ item.DiaMesAgendamento }}</OmtTypography>
                </CBox>
                <CBox w="80%">
                  <OmtTypography as="h2-bold">{{ item.MesAgendamentoExtenso }}</OmtTypography>
                </CBox>
                <CBox w="auto">
                  <CFlex>
                    <OmtIcons name="clock" /><OmtTypography as="p1" ml="2">{{ item.Hora }}</OmtTypography>
                  </CFlex>
                </CBox>
              </CBox>
              <hr />
              <CBox mt="3">
                <OmtTypography as="h3-bold">{{
                  item.Assunto.charAt(0).toUpperCase() + item.Assunto.slice(1).toLowerCase()
                }}</OmtTypography>
              </CBox>
              <CBox my="2">
                <OmtTypography as="p1">{{ item.TempoRestante }}</OmtTypography>
              </CBox>
              <CGrid template-columns="repeat(2, 1fr)" gap="3" mt="16px">
                <CBox>
                  <OmtButton height="40px" w="100%" variant="outline" fontSize="14px" @click="irParaDetalhes(item)">{{
                    textAction(item.Situacao)
                  }}</OmtButton>
                </CBox>
                <CBox>
                  <OmtButton
                    :disabled="item.Situacao != 1"
                    height="40px"
                    w="90%"
                    fontSize="14px"
                    @click="irParaChamada(item)"
                    >Entrar</OmtButton
                  >
                </CBox>
              </CGrid>
            </CBox>
          </div>
        </OmtCard>
      </OmtSwiperSlide>
    </OmtSwiper>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import BlockList from "@/components/Molecules/BlockList";
import ResouceModal from "@/components/Organisms/DrOmint/ResourceModal";

export default {
  name: "TaskList",
  data() {
    return {
      exibirModalPermissao: false,
      timer: null,
      taskSelected: 0
    };
  },
  components: { BlockList, ResouceModal },
  computed: {
    ...mapState("tasks", ["taskList"]),
    ...mapState("biometrics", ["camera", "microfone"]),
    ...mapGetters("biometrics", ["onNative"]),
    v25() {
      return Number.parseInt(localStorage.getItem("native_version")) >= 25;
    },
    shouldRequestResources() {
      return this.v25 && this.onNative && (this.camera !== "granted" || this.microfone !== "granted");
    },
    exibir() {
      return this.taskList.length > 0;
    }
  },
  watch: {
    shouldRequestResources(value) {
      if (!value) this.exibirModalPermissao = value;
    }
  },
  methods: {
    ...mapActions("tasks", ["featchTasks"]),
    textAction(situacao) {
      switch (situacao) {
        case 0:
          return "Agendar";
        default:
          return "Ver detalhes";
      }
    },
    limparTimer() {
      if (this.timer !== null) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    agendarConsultaPeriodica() {
      this.featchTasks();
      this.limparTimer();
      this.timer = setInterval(() => this.featchTasks(), 60000);
    },
    irParaChamada(task) {
      if (this.shouldRequestResources) {
        this.exibirModalPermissao = true;
        return;
      }
      if (task.Situacao !== 1) return;
      this.$router.push({
        name: "tasks.precall",
        params: {
          chamado: task.Chamado
        }
      });
    },
    obterCorSituacao(situacao) {
      switch (situacao) {
        case 1:
          return "var(--colors-light-support-warning)";
        case 2:
          return "#172972";
        case 3:
          return "var(--colors-light-support-success)";
        case 4:
          return "var(--colors-light-support-error)";
        case 5:
          return "var(--colors-light-primary-navy-blue)";
        default:
          return "#C31800";
      }
    },
    irParaDetalhes(task) {
      switch (task.Situacao) {
        case 0:
          this.$router.push({
            path: "/agendamento",
            query: {
              chamado: task.Chamado
            }
          });
          break;
        default:
          this.$router.push({
            path: "/video-call/details",
            query: {
              chamado: task.Chamado
            }
          });
          break;
      }
    }
  },
  beforeDestroy() {
    this.limparTimer();
  },
  created() {
    this.featchTasks();
    const secs = new Date().getSeconds();
    this.timer = setTimeout(() => this.agendarConsultaPeriodica(), 1000 * (60 - secs));
  }
};
</script>

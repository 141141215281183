<template>
  <omint-page>
    <Header name="Welcome" titleLabel="" />
    <omint-page-content>
      <CBox>
        <CBox
          v-if="order > 0 && totalSteps > 0"
          padding="0"
          margin-bottom="32px"
          mx="auto"
          width="calc(100vw - 40px)"
          max-width="1024px"
        >
          <OmtStepper :currentStep="step" :totalSteps="totalSteps" :inverseColor="true" />
        </CBox>
        <router-view></router-view>
      </CBox>
    </omint-page-content>
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";
import { mapActions, mapState } from "vuex";

export default {
  name: "Welcome",
  components: { Header },
  data() {
    return {
      step: 1,
      order: 0
    };
  },
  watch: {
    $route(route) {
      this.findStep(route);
    }
  },
  created() {
    this.checkForBiometric();
    this.checkForNativeShare();
    this.checkPermissionForResource("camera");
    this.checkPermissionForResource("microfone");
    this.checkPermissionForResource("location");
  },
  mounted() {
    this.findStep(this.$route);
  },
  computed: {
    ...mapState("myAccount", ["welcomeOnBoardTour"]),
    totalSteps() {
      if (!this.welcomeOnBoardTour?.length) return 0;
      const ignoreInitialCard = this.welcomeOnBoardTour.filter(w => w.order > 0);
      if (!ignoreInitialCard?.length) return 0;
      return ignoreInitialCard.length;
    }
  },
  methods: {
    ...mapActions("biometrics", [
      "checkForBiometric",
      "registerListeners",
      "checkForNativeShare",
      "checkPermissionForResource"
    ]),
    findStep(route) {
      if (!("idx" in route.params)) return;
      try {
        const id = Number(route.params.idx);
        let hasInitialCard = false;
        for (let i = 0; i < this.welcomeOnBoardTour.length; i++) {
          if (!hasInitialCard && this.welcomeOnBoardTour[i].order === 0) {
            hasInitialCard = true;
          }
          if (this.welcomeOnBoardTour[i].id === id) {
            this.step = hasInitialCard ? i : i + 1;
            this.order = this.welcomeOnBoardTour[i].order;
            break;
          }
        }
      } catch {
        this.step = 0;
      }
    }
  }
};
</script>

import {
  ObterOptin,
  AceiteOptinUsuario,
  AlterarSenha,
  ProgramaScreening,
  ProgramaScreeningAceite,
  ObterBlogPosts,
  ObterImagem,
  ObterOnBoardTour,
  EnviarTokenValidacao,
  InformarCodigoValidacao,
  SituacaoSessao,
  PermissaoFuncionalidade,
  RegistrarAcesso,
  RespostaOnBoardTour,
  VerificarAlteracaoSenhaPendente
} from "@/api/minhaConta";
import { EnviarCodigoRecuperarSenha, InformarCodigoRecuperarSenha } from "../api/api";
import { ExisteChamadoPendenteProcessamento, RegistroNavegacaoRef } from "@/api/atendimento";
import { ObterTelefones } from "@/api/associado";
import { checkUpdatePermission, allowUpdate } from "@/utils/checkReadonlyUtil";

const ONBOARD_FLUXO_WELCOME = "BEM-VINDO";
const ONBOARD_FLUXO_CADASTRO = "ATUALIZA-C";

/**
 * terms: {Descricao: String, Id: Int, Link: String}
 * acceptance: {[Id]: 'S' | 'N'}
 * */
const initialState = {
  terms: [],
  acceptance: {},
  resetPassword: {},
  screeningTerms: {},
  blogPosts: [],
  welcomeOnBoardTour: [],
  welcomeOnBoardTourStep: 0,
  welcomeOnBoardTourCode: "",
  changePasswordRequired: false
};

export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  actions: {
    async fetchTerms(context) {
      const terms = await ObterOptin({
        login: context.rootState.saude.username
      });

      if (terms && terms.Data) {
        context.commit("SET_TERMS", terms.Data);
      }
    },
    async fetchWelcomeOnBoardTour(context) {
      try {
        let welcomeOnBoardTourCode = ONBOARD_FLUXO_WELCOME;
        let boards = await ObterOnBoardTour({
          vinculo: context.rootState.saude.vinculo,
          codigo: welcomeOnBoardTourCode
        });

        const filtrarPerguntas = (perguntas, filtrarPendentes = true) => {
          const v25 = Number.parseInt(localStorage.getItem("native_version")) >= 25;
          const onNative = context.rootGetters["biometrics/onNative"];
          const hasBiometric = context.rootState.biometrics.hasBiometric;
          const allowSettings = (v25 && onNative) || hasBiometric;

          return perguntas.filter(b => {
            if (!b.Rota && b.Ordem > 0) return false;
            if (b.Rota === "/settings") return allowSettings;
            if (filtrarPendentes) return !b.Resposta || b.Resposta === "P";

            return true;
          });
        };

        let perguntasFluxo = boards?.Data;

        //fluxo bem vindo
        if (perguntasFluxo?.length) {
          //Retorna perguntas puladas, sem respostas e válidas
          perguntasFluxo = filtrarPerguntas(boards.Data);
        }

        //fluxo atualização cadastral
        if (!perguntasFluxo?.length) {
          //nao existem perguntas pendentes no fluxo bem vindo, buscamos fluxo de atualizacao cadastral
          welcomeOnBoardTourCode = ONBOARD_FLUXO_CADASTRO;
          boards = await ObterOnBoardTour({
            vinculo: context.rootState.saude.vinculo,
            codigo: welcomeOnBoardTourCode
          });

          //Retorna perguntas puladas, sem respostas e válidas
          perguntasFluxo = filtrarPerguntas(boards.Data);

          if (!perguntasFluxo?.length) {
            context.commit("SET_WELCOME_ONBOARD_TOUR_CODE", "");
            context.commit("SET_WELCOME_ONBOARD_TOUR", []);
            return;
          }
        }

        if (perguntasFluxo?.length) {
          //normaliza steps
          let welcomeOnboard = filtrarPerguntas(boards.Data, false).map(b => ({
            id: b.Id,
            order: b.Ordem,
            title: b.Titulo,
            icon: b.Icone,
            subtitle: b.Subtitulo,
            description: b.Descricao,
            pathTo: b.Rota,
            accessLabel: b.AcaoAcessar,
            nextLabel: b.AcaoPular,
            currentResponse: b.Resposta
          }));

          context.commit("SET_WELCOME_ONBOARD_TOUR_CODE", welcomeOnBoardTourCode);
          context.commit("SET_WELCOME_ONBOARD_TOUR", welcomeOnboard);
          return;
        }

        context.commit("SET_WELCOME_ONBOARD_TOUR_CODE", "");
        context.commit("SET_WELCOME_ONBOARD_TOUR", []);
        return;
      } catch (e) {
        context.commit("SET_WELCOME_ONBOARD_TOUR_CODE", "");
        context.commit("SET_WELCOME_ONBOARD_TOUR", []);
      }
    },
    async checkhWelcomeOnBoardTourPages(context) {
      if (!context.state.welcomeOnboard?.length) return;
      const update = allowUpdate;
      const menu = context.rootState.saude.menu ?? [];
      const menusFavoritos = context.rootState.saude.menusFavoritos ?? [];
      const welcomeOnboard = context.state.welcomeOnboard.filter(b => {
        if (!b.pathTo && b.order > 0) return false;
        let valid = !!menu.find(m => m.View && m.View.startsWith(b.pathTo));
        if (!valid) {
          valid = !!menusFavoritos.find(m => m.View && m.View.startsWith(b.pathTo));
        }
        if (!valid && update) {
          RespostaOnBoardTour({
            vinculo: context.rootState.saude.vinculo,
            codigo: context.state.welcomeOnBoardTourCode,
            id: b.id,
            resposta: "X"
          });
        }
        return valid;
      });
      context.commit("SET_WELCOME_ONBOARD_TOUR", welcomeOnboard);
    },
    async fetchScreeningTerms(context) {
      const terms = await ProgramaScreening({
        vinculo: context.rootState.saude.vinculo
      });

      if (terms && terms.Data) {
        context.commit("SET_SCREENING_TERMS", terms.Data);
      }
    },
    async acceptScreeningTerms(context) {
      checkUpdatePermission();
      return await ProgramaScreeningAceite({
        vinculo: context.rootState.saude.vinculo,
        login: context.rootState.saude.username
      });
    },
    async answerToTerms(context, { id, ans }) {
      context.commit("ANSWER_TERM", { id, ans });
    },
    async submitWelcomeOnBoardTour(context, { id, option }) {
      const newResponse = option ? "A" : "P";
      try {
        checkUpdatePermission();
        await RespostaOnBoardTour({
          vinculo: context.rootState.saude.vinculo,
          codigo: context.state.welcomeOnBoardTourCode,
          id,
          resposta: newResponse
        });
      } finally {
        let changedOnBoard = {};
        const newWelcomeOnBoardTour = context.state.welcomeOnBoardTour.map(b => {
          const newObBoard = { ...b };
          if (b.id !== id) return newObBoard;
          newObBoard.currentResponse = newResponse;
          changedOnBoard = newObBoard;
          return newObBoard;
        });
        const currentStep = changedOnBoard.order === 0 && newResponse === "P" ? 0 : changedOnBoard.id;
        context.commit("SET_WELCOME_ONBOARD_TOUR", newWelcomeOnBoardTour);
        const hasStepToGo = newWelcomeOnBoardTour.find(b => !b.currentResponse);
        if (hasStepToGo) {
          context.commit("SET_WELCOME_ONBOARD_TOUR_STEP", currentStep);
        } else {
          context.commit("SET_WELCOME_ONBOARD_TOUR_STEP", 0);
          context.dispatch("saude/fetchProfileMessages", null, { root: true });
        }
      }
    },
    async submitTerms(context) {
      if (!allowUpdate()) {
        context.commit("SET_TERMS", []);
        return;
      }

      const login = context.rootState.saude.username;
      const promisses = [];
      Object.keys(context.state.acceptance).forEach(k => {
        promisses.push(
          AceiteOptinUsuario({
            termo: k,
            aceite: context.state.acceptance[k],
            login
          })
        );
      });
      if (promisses.length > 0) {
        await await Promise.all(promisses);
      }
      await context.dispatch("fetchTerms");
    },
    async changePassword(context, { currentPassword, newPassword, auth }) {
      checkUpdatePermission();
      const result = await AlterarSenha({
        vinculo: context.rootState.saude.vinculo,
        senhaAtual: currentPassword,
        novaSenha: newPassword,
        autenticacao: auth
      });
      if (result && result.Data) {
        if (result.Data) {
          context.commit("SET_CHANGE_PASSWORD_REQUIRED", false);
        }
        return result.Data;
      }
      return false;
    },
    async sendResetPassordCode(context, { email }) {
      const resp = await EnviarCodigoRecuperarSenha({
        email
      });
      if (resp && resp.Data) {
        context.commit("SET_RESET_PASSWORD", resp.Data);
        return { success: true };
      }
      return { success: false, msg: "Não foi possível encontrar o usuário informado." };
    },
    async checkCodeAndChangePassword(_, { codigo, email, password }) {
      return await InformarCodigoRecuperarSenha({
        codigo,
        email,
        password
      });
    },
    async hasActiveTicket(context, { beneficiaryCode }) {
      if (!beneficiaryCode) return false;

      const response = await ExisteChamadoPendenteProcessamento({
        vinculo: context.rootState.saude.vinculo,
        beneficiario: beneficiaryCode,
        servico: 1
      });
      return !!(response instanceof Error ? false : response?.Data);
    },
    async fetchAvailablePhones(context) {
      const response = await ObterTelefones({
        vinculo: context.rootState.saude.vinculo
      });
      return response instanceof Error ? [] : response?.Data ?? [];
    },
    async fetchImage(context, { documento, original }) {
      return await ObterImagem({
        vinculo: context.rootState.saude.vinculo,
        documento,
        original
      });
    },
    async sendValidationCode(context, { phone, email, id, key, service }) {
      if (service && !service.startsWith("/")) {
        try {
          service = context.rootGetters[service];
        } catch (error) {
          window.$log.message(`using service ${service}`);
        }
      }
      const response = await EnviarTokenValidacao({
        vinculo: context.rootState.saude.vinculo,
        telefone: phone,
        email,
        id,
        chave: key,
        servico: service
      });
      if (response instanceof Error || !response?.Data)
        return {
          enviado: false
        };
      return response.Data;
    },
    async validateCode(context, { phone, email, id, key, code, auth, service }) {
      if (service && !service.startsWith("/")) {
        try {
          service = context.rootGetters[service];
        } catch (error) {
          window.$log.message(`using service ${service}`);
        }
      }
      const response = await InformarCodigoValidacao({
        vinculo: context.rootState.saude.vinculo,
        telefone: phone,
        email,
        id,
        chave: key,
        codigo: code,
        autenticacao: auth,
        servico: service
      });
      return response instanceof Error ? false : response?.Data ?? false;
    },
    async sessionState(context) {
      try {
        if (!context.rootState.saude.vinculo) return true;

        const situacao = await SituacaoSessao({
          vinculo: context.rootState.saude.vinculo
        });
        return situacao instanceof Error ? true : situacao?.Data ?? true;
      } catch {
        return true;
      }
    },
    async registerAccess(context, { servico, funcionalidade, id }) {
      try {
        let vinculo = context.rootState.saude.vinculo;
        if (!vinculo && context.rootState.saude.vinculos?.length) {
          vinculo = context.rootState.saude.vinculos[0].Vinculos[0].NumeroIdentificacaoVinculo;
        }
        if (!vinculo) return true;
        if (!allowUpdate()) return true;

        RegistrarAcesso({
          vinculo,
          dsUsuario: context.rootState.saude.username,
          servico,
          funcionalidade,
          id
        });
        return true;
      } catch {
        return false;
      }
    },
    async registerNavigation(context, { classificacao }) {
      try {
        let vinculo = context.rootState.saude.vinculo;
        if (!vinculo && context.rootState.saude.vinculos?.length) {
          vinculo = context.rootState.saude.vinculos[0].Vinculos[0].NumeroIdentificacaoVinculo;
        }
        if (!vinculo) return true;
        if (!allowUpdate()) return true;

        RegistroNavegacaoRef({
          vinculo: context.rootState.saude.vinculo,
          chamadoReferenciado: context.rootState.saude.chamadoRef,
          classificacao
        });
        return true;
      } catch {
        return false;
      }
    },
    async fetchAccessResource(context, { funcionalidade, permissao }) {
      const response = await PermissaoFuncionalidade({
        vinculo: context.rootState.saude.vinculo,
        funcionalidade,
        permissao
      });
      return response instanceof Error ? false : response?.Data ?? false;
    },
    async fetchBlogPosts(context) {
      if (context.state.blogPosts?.length) return;
      const response = await ObterBlogPosts({
        vinculo: context.rootState.saude.vinculo
      });
      const blogPosts = response instanceof Error ? [] : response?.Data ?? [];
      context.commit("SET_BLOG_POSTS", blogPosts);
    },
    async fetchChangePasswordRequired(context) {
      if (!allowUpdate()) return;
      const response = await VerificarAlteracaoSenhaPendente({
        vinculo: context.rootState.saude.vinculo
      });
      const changePasswordRequired = response instanceof Error ? false : response?.Data ?? false;
      context.commit("SET_CHANGE_PASSWORD_REQUIRED", changePasswordRequired);
    }
  },
  mutations: {
    LOGOUT_USER: state => Object.keys(state).forEach(p => (state[p] = initialState[p])),
    RESET_CONTEXT: state => Object.keys(state).forEach(p => (state[p] = initialState[p])),
    SET_TERMS: (state, next) => (state.terms = next),
    SET_SCREENING_TERMS: (state, next) => (state.screeningTerms = next),
    SET_RESET_PASSWORD: (state, next) => (state.resetPassword = next),
    SET_BLOG_POSTS: (state, blogPosts) => (state.blogPosts = blogPosts),
    SET_CHANGE_PASSWORD_REQUIRED: (state, next) => (state.changePasswordRequired = next),
    SET_WELCOME_ONBOARD_TOUR: (state, next) => (state.welcomeOnBoardTour = next),
    SET_WELCOME_ONBOARD_TOUR_STEP: (state, next) => (state.welcomeOnBoardTourStep = next),
    SET_WELCOME_ONBOARD_TOUR_CODE: (state, next) => (state.welcomeOnBoardTourCode = next),
    ANSWER_TERM: (state, { id, ans }) => (state.acceptance[id] = ans)
  }
};

<template>
  <div id="page" class="page">
    <div v-if="!isAppUpdated" class="app-loading">
      <img :src="logoImg" />
      <p>Verificando atualizações...</p>
    </div>
    <template v-else>
      <form class="cover">
        <div class="form">
          <CFlex justifyContent="center" mb="50px">
            <img :src="logoLogin" />
          </CFlex>

          <CFlex mb="3" justifyContent="center">
            <OmtTypography fontSize="22px" fontWeight="700" as="p1" color="var(--colors-light-primary-navy-blue)" mr="1"
              >Olá,</OmtTypography
            >
            <OmtTypography fontSize="22px" fontWeight="400" as="p1">é bom te ver aqui!</OmtTypography>
          </CFlex>
          <CFlex justifyContent="center">
            <OmtTypography as="h2-regular" mb="5" color="var(--colors-light-primary-gray2)"
              >Insira seus dados para acessar o APP</OmtTypography
            >
          </CFlex>
          <div class="form-group">
            <OmtInput
              v-model="input.username"
              data-cy="login"
              autocapitalize="none"
              autocomplete="username"
              placeholder="CPF"
              type="text"
              @input="toLowerCase"
              v-on:keydown.enter.prevent="checkEnter"
              borderColor="var(--colors-light-secondary-divider)"
              px="2"
              class="InputLogin"
            />
            <span v-show="input.username === ''" class="required">(obrigatório)</span>
          </div>

          <div class="form-group">
            <OmtInput
              v-model="input.password"
              data-cy="password"
              :type="passwordShow ? 'text' : 'password'"
              autocapitalize="off"
              autocomplete="current-password"
              autocorrect="off"
              placeholder="Senha"
              v-on:keyup="
                () => {
                  this.invalidPwd = false;
                }
              "
              v-on:keydown.enter.prevent="checkEnter"
              px="2"
              class="InputLogin"
            />
            <OmtIcons
              size="lg"
              :name="passwordShow ? 'eye-slash' : 'eye'"
              color="var(--colors-light-primary-navy-blue)"
              position="absolute"
              right=".5rem"
              zIndex="2"
              @click.prevent="passwordShow = !passwordShow"
            />
            <span v-show="input.password === ''" class="required">(obrigatório)</span>
          </div>
          <CFlex mt="5" mb="8" justifyContent="space-between">
            <CBox>
              <router-link to="/first-access/terms">
                <OmtTypography as="h3-bold" color="var(--colors-light-primary-navy-blue)"
                  >Primeiro acesso</OmtTypography
                >
              </router-link>
            </CBox>
            <CBox>
              <router-link to="/forgot/reset">
                <OmtTypography as="h3-bold" color="var(--colors-light-primary-navy-blue)"
                  >Esqueci minha senha</OmtTypography
                >
              </router-link>
            </CBox>
          </CFlex>
          <OmtButton
            variant="solid"
            width="100%"
            height="50px"
            border="0.5px solid var(--colors-light-secondary-divider)"
            borderRadius="40px"
            :disabled="isLoading"
            data-cy="button-entrar"
            @click.prevent.stop="login()"
          >
            <OmtTypography as="h1-bold" color="white">Entrar</OmtTypography>
          </OmtButton>
          <CFlex v-if="shoudUseBiometric" mt="50px">
            <CBox justifyContent="center" textAlign="center" w="100%" @click="authenticateUser()">
              <OmtIcons size="lg" name="expand" color="var(--colors-light-primary-gray1)" />
              <OmtTypography as="h3-bold">Entrar<br />com biometria</OmtTypography>
            </CBox>
          </CFlex>
          <div class="margin-head"></div>
        </div>
      </form>

      <InstallPWA />
    </template>
  </div>
</template>

<script>
import InstallPWA from "@/components/Molecules/InstallPWA";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import { ROTA_BASE, ROTA_PADRAO, ROTA_VINCULOS, ROTA_OPTIN } from "@/utils/perfilRouteUtil";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import equalweb from "@/components/Organisms/Equalweb/mixin.js";

export default {
  mixins: [equalweb],
  computed: {
    ...mapGetters("biometrics", ["onNative"]),
    ...mapState("saude", ["userId", "credentials", "vinculo"]),
    ...mapState("accessManagement", ["appLocked"]),
    ...mapState("biometrics", ["shoudUseBiometric", "isUserAuthenticated", "listenersRegistred"]),
    equalButtonStyle() {
      return "rigth: 125px; top: 85%; width: 80px; height: 80px; --indscale: 0.6; --indmaincolor: #102a46;";
    },
    iOS() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    hasValidNextUrl() {
      return (
        this.$route.query.nextUrl &&
        this.$route.query.nextUrl !== ROTA_BASE &&
        this.$route.query.nextUrl !== ROTA_PADRAO &&
        this.$route.query.nextUrl !== ROTA_OPTIN &&
        this.$route.query.nextUrl !== ROTA_VINCULOS
      );
    },
    logoImg() {
      return process.env.VUE_APP_BASE_URL + "images/omint/logo-omint-app.png";
    },
    logoLogin() {
      return process.env.VUE_APP_BASE_URL + "images/omint/logo2023.png";
    }
  },
  data() {
    return {
      isAppUpdated: false,
      passwordType: "password",
      passwordShow: false,
      passwordIcon: "fa fa-eye hide-pwd",
      input: {
        username: null,
        password: null
      },
      isLoading: true,
      backgroundSize: "",
      showTooltip: false
    };
  },
  watch: {
    async isUserAuthenticated(authenticated) {
      if (authenticated) {
        if (!navigator.onLine) {
          window.dispatchEvent(new Event("offline"));
          return;
        }
        try {
          this.isLoading = true;
          this.$root.$emit("show-loading");
          await this.unlockApp();
          const nextRoute = await this.refreshContext();
          if (nextRoute !== "" && nextRoute !== ROTA_PADRAO) {
            await this.$router.replace(nextRoute);
          }
        } catch (err) {
          window.$log.error("Erro de acesso por biometria", err);
        } finally {
          this.isLoading = false;
          this.$root.$emit("hide-loading");
        }
      }
    }
  },
  created() {
    window.addEventListener("resize", this.onResizeHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResizeHandler);
  },
  async mounted() {
    this.$root.$emit("hide-loading");
    if ("localhost:8080" !== document.location.host && (this.iOS || !this.onNative)) {
      try {
        const updated = await this.forceSWupdate();
        if (updated) {
          window.location.reload(true);
          setTimeout(() => this.initConfig(), 5000);
        } else {
          await this.initConfig();
        }
      } catch (err) {
        window.$appInsights.trackException(err, { serviceWorkerUpdate: true });
        setTimeout(() => this.initConfig(), 1000);
      }
    } else {
      await this.initConfig();
    }
  },
  methods: {
    ...mapActions(["resetContext"]),
    ...mapActions("saude", ["loginUser", "refreshContext", "forceSWupdate"]),
    ...mapActions("accessManagement", ["unlockApp"]),
    ...mapActions("biometrics", [
      "authenticateUser",
      "registerListeners",
      "setAuthenticated",
      "setUserAuthenticationState"
    ]),
    ...mapMutations("saude", ["LOGOUT_USER"]),
    async initConfig() {
      this.isAppUpdated = true;
      this.isLoading = false;
      this.$root.$emit("hide-loading");
      this.registerListeners();
      this.onResizeHandler();
      this.initEqualweb({
        ...this.defaultEqualwebButtonStyle,
        vPosition: [null, "30%"],
        scale: ["0.35", "0.35"]
      });
      if (this.userId && !this.appLocked) {
        await this.resetContext();
      }
      if (this.shoudUseBiometric && this.listenersRegistred) {
        setTimeout(() => {
          this.authenticateUser();
        }, 300); // register listeners take some time we can not mesure
      }
      this.showsTooltip();
    },
    showsTooltip() {
      this.showTooltip = true;
      setTimeout(() => {
        this.showTooltip = false;
      }, 5000);
    },
    showHidePwd() {
      if (this.passwordType === "password") {
        this.passwordType = "text";
        this.passwordIcon = "fa fa-eye-slash show-pwd";
      } else {
        this.passwordType = "password";
        this.passwordIcon = "fa fa-eye hide-pwd";
      }
    },
    async login() {
      if (!navigator.onLine) {
        window.dispatchEvent(new Event("offline"));
        return;
      }
      if (this.input.username !== "" && this.input.password !== "") {
        this.isLoading = true;
        this.$root.$emit("show-loading");
        let ok = false;
        let nextRoute = "";
        try {
          nextRoute = await this.loginUser(this.input);
          ok = nextRoute !== "" && nextRoute !== ROTA_PADRAO;
        } catch (error) {
          ok = false;
        } finally {
          this.isLoading = false;
          this.$root.$emit("hide-loading");
        }
        if (ok) {
          this.setUserAuthenticationState(true);
          if (this.shoudUseBiometric) {
            // is user log in using password, also authenticate like fingerprint to please auth.js service
            this.setAuthenticated();
          }
          await this.unlockApp();
          if (nextRoute.startsWith("/")) {
            if (this.hasValidNextUrl) {
              if (nextRoute === ROTA_VINCULOS || nextRoute === ROTA_OPTIN) {
                await this.$router.replace({
                  path: nextRoute,
                  query: {
                    nextUrl: this.$route.query.nextUrl
                  }
                });
              } else {
                await this.$router.replace(this.$route.query.nextUrl);
              }
            } else {
              await this.$router.replace(nextRoute);
            }
          } else {
            await this.LOGOUT_USER();
            window.location = nextRoute;
          }
        } else {
          Swal.fire({
            title: "Aviso",
            text: "Dados de acesso incorretos. Caso não possua uma conta registrada, realize o primeiro acesso.",
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: "Tentar novamente",
            denyButtonText: `Recuperar senha`,
            cancelButtonText: "Primeiro acesso",
            allowEscapeKey: false,
            allowOutsideClick: false
          }).then(result => {
            if (result.isConfirmed) {
              this.invalidUser = true;
              this.invalidPwd = true;
            } else if (result.isDenied) {
              this.$router.push("/forgot/reset");
            } else {
              this.$router.push("/first-access/terms");
            }
          });
        }
      }
    },
    checkEnter() {
      if (this.input.username !== "" && this.input.password !== "") {
        this.login();
      }
    },
    toLowerCase() {
      this.input.username = this.input.username?.trim()?.toLowerCase();
    },
    onResizeHandler() {
      let pw = 0.0;
      let ph = 0.0;
      if (window.innerWidth >= 992) {
        //3199 × 1600
        pw = window.innerWidth / 3199;
        ph = window.innerHeight / 1600;
      } else {
        //469 × 834
        pw = window.innerWidth / 469;
        ph = window.innerHeight / 834;
      }
      if (pw > 0 || ph > 0) {
        // tela maior que a imagem
        if (pw > ph) {
          this.backgroundSize = " background-size: 100% auto;";
        } else {
          this.backgroundSize = " background-size: auto 100%;";
        }
      } else {
        this.backgroundSize = " background-size: auto";
      }
    }
  },
  components: {
    InstallPWA
  }
};
</script>
<style scoped>
.InputLogin::placeholder {
  font-size: 14px;
}

.cover {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

.box {
  clear: both;
  margin-top: 5px;
  height: 28px;
}

.box .item {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 23px;
  vertical-align: baseline;
}

.box .item a {
  display: block;
  color: white;
  line-height: 23px;
}

.right {
  text-align: right;
}

.form {
  display: block;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 490px;
  margin: auto;
  color: white;
  font-size: 13px;
  padding: 95px;
  background: #fff;
  border-radius: 1rem;
  z-index: 2;
  transform: translate(-50%, -50%);
}

@media all and (orientation: landscape) and (max-width: 992px) {
  .form {
    position: relative;
    top: auto;
    left: auto;
    padding: 18px 95px;
    transform: unset;
  }
}

.form-group {
  display: flex;
  align-items: center;
  vertical-align: middle;
  position: relative;
}

.form-group i {
  position: absolute;
  font-size: 14px;
}

.form-group i:first-child {
  left: 10px;
}

.form-group i:last-child {
  right: 10px;
}
.hide-pwd,
.show-pwd {
  margin-left: 255px;
  font-weight: 400 !important;
}

.placeholder {
  position: absolute;
  color: #4a89dc;
  font-size: 10px;
  margin-left: 25px;
  margin-top: -20px;
}

.required {
  display: none;
  position: absolute;
  color: #968b83;
  font-size: 12px;
  margin-left: 180px;
}

.login-button {
  display: block;
  color: white;
  background-color: transparent;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 20px;
  margin: 0;
  margin-top: 30px;
  font-weight: 800;
  width: 275px;
  height: 50px;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid white;
}

.round {
  border-radius: 100%;
}

.login-finger-icon > i {
  transform: scale(1.5);
}

.login-finger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  margin: 30px 0 30px calc(50% - 0.8rem);
}

.login-button[disabled] {
  opacity: 0.5;
}

.login-button:hover,
.login-button:focus {
  border: 1px solid white;
}

@media only screen and (min-width: 992px) {
  .bg {
    /** dimensões da imagem 1600 x 3200
        fator 0.5
        se o fator for da largura x altura for maior que 2
        precisa aplicar a diferença */
    width: 100vw;
    background-size: auto auto;
  }

  .page {
    background: #e5e5e5;
  }
}

.form-tooltip {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  background-color: white;
  padding: 8px 12px;
  position: absolute;
  width: 275px;
  top: -50px;
  right: 0;
}

.form-tooltip :after {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid white;
  position: absolute;
  top: 94%;
  left: calc(100% - 26px);
}

.form-tooltip p {
  margin: 0;
  color: #737373;
  font-size: 12px;
}

.form-tooltip i {
  color: #818181;
}

.tooltip-leave-active {
  transition: opacity 0.5s;
}

.tooltip-enter,
.tooltip-leave-to {
  opacity: 0;
}
</style>

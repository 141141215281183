import Welcome from "@/pages/welcome/index.vue";
import WelcomeItem from "@/pages/welcome/idx.vue";

const routes: Route[] = [
  {
    path: "/welcome",
    component: Welcome,
    meta: {
      checkPathDisabled: true
    },
    children: [
      {
        path: ":idx",
        component: WelcomeItem,
        meta: {
          checkPathDisabled: true
        }
      }
    ]
  }
];

export default routes;

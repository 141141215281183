import Vue from "vue";
import {
  ObterBancosDisponiveis,
  ObterAgenciasDisponiveis,
  ObterBeneficiariosTitulo,
  ListarContaCorrenteCredito,
  ObterContaCorrenteParaCredito,
  AlterarContaCredito,
  ConsultarRequisicaoAlteracaoContaCredito,
  CadastroContaCredito
} from "@/api/associado";
import { ExisteChamadoPendenteProcessamento } from "@/api/atendimento";
import { checkUpdatePermission } from "@/utils/checkReadonlyUtil";

const initialState = {
  banks: [],
  agencies: [],
  emails: [],
  phones: [],
  accounts: [],
  newBank: "",
  newAgency: "",
  newAccount: "",
  newAccountDigit: "",
  protocol: "",
  requestUrl: "",
  bankCode: "",
  email: "",
  phone: "",
  beneficiaries: [],
  selectedBeneficiary: ""
};

// vuex module
export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  actions: {
    async hasActiveTicket(context, { beneficiaryCode }) {
      if (!beneficiaryCode) return false;

      const response = await ExisteChamadoPendenteProcessamento({
        vinculo: context.rootState.saude.vinculo,
        beneficiario: beneficiaryCode,
        servico: 1
      });
      return !!(response instanceof Error ? false : response?.Data);
    },
    async fetchBeneficiaries(context) {
      if (context.state.beneficiaries.length > 0) return;

      const response = await ObterBeneficiariosTitulo({
        vinculo: context.rootState.saude.vinculo,
        dependentes: false,
        servico: 1
      });
      const beneficiaries =
        response instanceof Error
          ? []
          : response.Data.map(b => {
              return {
                value: b.Sequencia,
                label: b.Nome,
                document: b.DocumentoFormatado ?? b.Documento
              };
            });
      await context.commit("SET_BENEFICIARIES", beneficiaries);
    },
    async fetchAccounts(context, { beneficiaryCode }) {
      if (!beneficiaryCode) return [];

      const accounts = await ListarContaCorrenteCredito({
        vinculo: context.rootState.saude.vinculo,
        beneficiario: beneficiaryCode,
        servico: 1
      });

      return accounts?.Data || [];
    },
    async fetchCurrentAccount(context) {
      const account = await ObterContaCorrenteParaCredito({
        vinculo: context.rootState.saude.vinculo
      });

      if (account.Data) {
        await context.commit("SET_ACCOUNT", account.Data);
      }
    },
    async feachTicket(_, ticket) {
      return await ConsultarRequisicaoAlteracaoContaCredito({ chave: ticket });
    },
    async listAvailableBanks(context) {
      if (context.state.banks.length > 0) return;
      const banks = await ObterBancosDisponiveis({
        codigo: "",
        descricao: "",
        autorizaDebito: false,
        autorizaCredito: true
      });
      if (banks?.Data?.length) {
        await context.commit("SET_BANKS", banks.Data);
      }
    },
    async listAvailableAgencies(context, { bankCode }) {
      const agencies = await ObterAgenciasDisponiveis({
        banco: bankCode,
        codigo: "",
        descricao: ""
      });
      if (agencies.Data) {
        await context.commit("SET_AGENCIES", agencies.Data);
      } else {
        return agencies;
      }
    },
    async submitNewBankInfo(context) {
      checkUpdatePermission();
      const resp = await AlterarContaCredito({
        vinculo: context.rootState.saude.vinculo,
        banco: context.state.bankCode,
        agencia: context.state.newAgency,
        conta: context.state.newAccount,
        digito: context.state.newAccountDigit,
        emailContato: context.state.email,
        telefoneContato: context.state.phone,
        emailLogado: context.rootState.saude.username,
        usuarioLogado: context.rootState.saude.userId
      });

      if (resp.Data) {
        await context.commit("SET_PROTOCOL", resp.Data.Protocolo);
        await context.commit("SET_REQUESTURL", resp.Data.UrlRequisicao);
      }
    },
    async submitBeneficiaryBankInfos(
      context,
      {
        beneficiario,
        idConta,
        banco,
        agencia,
        conta,
        digito,
        excluir,
        principal,
        emailContato,
        telefoneContato,
        autenticacao
      }
    ) {
      checkUpdatePermission();
      const resp = await CadastroContaCredito({
        vinculo: context.rootState.saude.vinculo,
        beneficiario,
        idConta,
        banco,
        agencia,
        conta,
        digito,
        excluir,
        principal,
        emailContato,
        telefoneContato,
        usuarioLogado: context.rootState.saude.userId,
        autenticacao
      });

      return resp instanceof Error ? "" : resp.Data?.Protocolo;
    }
  },
  mutations: {
    LOGOUT_USER: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    RESET_CONTEXT: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    SET_AGENCIES: (state, nextAgencies) => {
      Vue.set(state, "agencies", nextAgencies);
    },
    SET_BANKS: (state, nextBanks) => {
      Vue.set(state, "banks", nextBanks);
    },
    SET_EMAILS: (state, nextEmails) => {
      Vue.set(state, "emails", nextEmails);
    },
    SET_PHONES: (state, nextPhones) => {
      Vue.set(state, "phones", nextPhones);
    },
    SET_ACCOUNT: (state, nextAccount) => {
      Vue.set(state, "account", nextAccount);
    },
    SET_NEWBANK: (state, value) => {
      Vue.set(state, "newBank", value);
    },
    SET_NEWAGENCY: (state, value) => {
      Vue.set(state, "newAgency", value);
    },
    SET_NEWACCOUNT: (state, value) => {
      Vue.set(state, "newAccount", value);
    },
    SET_NEWACCOUNTDIGIT: (state, value) => {
      Vue.set(state, "newAccountDigit", value);
    },
    SET_PROTOCOL: (state, value) => {
      Vue.set(state, "protocol", value);
    },
    SET_REQUESTURL: (state, value) => {
      Vue.set(state, "requestUrl", value);
    },
    SET_BANKCODE: (state, value) => {
      Vue.set(state, "bankCode", value);
    },
    SET_PHONE: (state, value) => {
      Vue.set(state, "phone", value);
    },
    SET_EMAIL: (state, value) => {
      Vue.set(state, "email", value);
    },
    SET_BENEFICIARIES: (state, value) => {
      Vue.set(state, "beneficiaries", value);
    },
    SET_SELECTED_BENEFICIARY: (state, value) => {
      Vue.set(state, "selectedBeneficiary", value);
    }
  }
};

import { render, staticRenderFns } from "./types.vue?vue&type=template&id=4c6aa927&scoped=true&"
import script from "./types.vue?vue&type=script&lang=js&"
export * from "./types.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c6aa927",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFlex: require('@chakra-ui/vue').CFlex, CBox: require('@chakra-ui/vue').CBox})

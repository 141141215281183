import { recuperarDadosVinculo } from "@/utils/vinculoUtil";

const baseURL = `${process.env.VUE_APP_SERVER_URL}/Voucher`;

export async function CancelarAgendamento({ agendamento, vinculo }) {
  return window.$axios
    .post({
      url: `${baseURL}/CancelarAgendamento`,
      query: {
        numeroOPS: agendamento,
        idBeneficiario: vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterCredencialEletronica", error.message);
      return error;
    });
}

export async function ConfirmarAgendamento({
  vinculo,
  dataAgendamento,
  prestador,
  tipoVoucher,
  dataValidade,
  endereco
}) {
  const dadosVinculo = recuperarDadosVinculo(vinculo);
  //precisa estar formatado com 8 posições
  prestador = `00000000${prestador}`;
  prestador = prestador.substr(prestador.length - 8);
  const ano = dataAgendamento.getFullYear();
  let mes = `${dataAgendamento.getMonth() + 1}`;
  if (mes.length < 2) mes = `0${mes}`;
  let dia = `${dataAgendamento.getDate()}`;
  if (dia.length < 2) dia = `0${dia}`;

  return window.$axios
    .post({
      url: `${baseURL}/ConfirmarAgendamento`,
      data: {
        CodigoTitulo: dadosVinculo.codigo,
        SequenciaBeneficiario: dadosVinculo.sequencia,
        DataAgendamento: `${ano}-${mes}-${dia}`,
        CodigoPrestador: prestador,
        TipoVoucher: tipoVoucher,
        DataValidadeVoucher: dataValidade,
        EnderecoId: endereco
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ConfirmarAgendamento", error.message);
    });
}

export async function ConsultarAgendamento({ agendamento }) {
  return window.$axios
    .get({
      url: `${baseURL}/GetConsultar`,
      query: {
        id: agendamento
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ConsultarAgendamento", error.message);
    });
}

export async function Listar({ vinculo }) {
  const dadosVinculo = recuperarDadosVinculo(vinculo);

  return window.$axios
    .get({
      url: `${baseURL}/ListarOPSs`,
      query: {
        codigoTituloAssociado: dadosVinculo.codigo,
        sequenciaBeneficiario: dadosVinculo.sequencia
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("Listar", error.message);
    });
}

export async function ObterPrestadores({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterListaPrestadoresVoucher`,
      query: {
        idBeneficiario: vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterPrestadores", error.message);
    });
}

export async function ObterProtocoloVoucher({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterProtocoloVoucher`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterProtocoloVoucher", error.message);
    });
}

export async function ObterVoucher({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterVoucher`,
      query: {
        idBeneficiario: vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterVoucher", error.message);
    });
}

export async function ObterBeneficiarios({ vinculo, tipo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterBeneficiarios`,
      query: {
        vinculo,
        tipo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterBeneficiarios", error.message);
    });
}

export async function ObterTiposVacina({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterTiposVacina`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterTiposVacina", error.message);
    });
}

export async function GerarVoucher({ vinculo, tipo, beneficiarios }) {
  return window.$axios
    .post({
      url: `${baseURL}/GerarVoucher`,
      query: {
        vinculo,
        tipo
      },
      data: beneficiarios
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("GerarVoucher", error.message);
    });
}

export async function ObterDocumento({ key }) {
  const type = ".pdf";
  const queryString = new URLSearchParams({
    key,
    type
  }).toString();

  return `${baseURL}/ObterDocumento?${queryString}`;
}

<template>
  <div v-chakra v-bind="$attrs">
    <div ref="swiper" class="swiper">
      <div class="swiper-wrapper">
        <slot />
      </div>
    </div>

    <OmtSwiperPagination v-if="pagination" :totalSlides="totalSlides" :atualSlide="atualSlide" />
    <OmtSwiperNavigation
      v-if="navigation && totalSlides > navigationSize"
      @on-prev-button-click="PrevButtonClick"
      @on-next-button-click="NextButtonClick"
      :disabledPrev="start"
      :disabledNext="end"
      mt="16px"
    />
  </div>
</template>
<script>
/**
 * Swiper component based on https://swiperjs.com
 * @prop { options } https://swiperjs.com/swiper-api
 */
import Swiper from "swiper";
import "swiper/swiper.min.css";

export default {
  name: "OmtSwiper",
  props: {
    slidesPerView: {
      type: Number
    },
    spaceBetween: {
      type: Number,
      default: 16
    },
    breakPoints: {
      type: Object
    },
    reduced: {
      type: Boolean,
      default: true
    },
    options: {
      type: Object
    },
    grid: {
      type: Object
    },
    direction: {
      type: String,
      default: "horizontal"
    },
    navigation: {
      type: Boolean,
      default: false
    },
    navigationSize: {
      type: Number,
      default: 2
    },
    goToSlide: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: -1
    },
    pagination: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      swiper: null,
      atualSlide: 0,
      atualPage: 0,
      totalPages: 0,
      totalSlides: 0,
      start: true,
      end: false
    };
  },
  methods: {
    slideChange() {
      this.atualSlide = this.swiper.activeIndex;
      this.atualPage = this.swiper.realIndex;
      this.start = this.swiper.isBeginning;
      this.end = this.swiper.isEnd;
      this.$emit("slide-atual", this.swiper.activeIndex);
    },
    NextButtonClick() {
      if (!this.end) this.swiper.slideNext();
    },
    PrevButtonClick() {
      if (!this.start) this.swiper.slidePrev();
    }
  },
  watch: {
    goToSlide() {
      this.swiper.slideTo(this.goToSlide);
    }
  },
  async mounted() {
    this.swiper = await new Swiper(this.$refs.swiper, {
      slidesPerView: this.slidesPerView || "auto",
      spaceBetween: this.spaceBetween,
      breakpoints: this.breakPoints,
      direction: this.direction,
      pagination: this.pagination ? { el: ".swiper-pagination" } : false,
      grid: this.grid,
      grabCursor: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      ...this.options
    });

    this.totalSlides = this.size !== -1 ? this.size : this.swiper.slides.length;
    this.totalPages = Math.ceil(this.totalSlides / 2);

    this.swiper.on("slideChange", () => {
      this.slideChange();
    });
  },
  updated() {
    if (!this.swiper || this.size === -1) return;
    if (this.size !== this.swiper.slides.length) {
      this.totalSlides = this.size;
      this.totalPages = Math.ceil(this.totalSlides / 2);
      this.swiper.update();
      this.slideChange();
    }
  }
};
</script>

<style lang="scss">
.swiper {
  width: 100%;
}
</style>

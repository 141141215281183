<template>
  <CBox v-if="tour">
    <OmtIcons :name="tour.icon" mb="32px" size="3x" color="var(--colors-light-primary-navy-blue)" />
    <OmtTypography v-if="tour.subtitle" as="h1-regular" mb="32px">
      <span v-html="tour.subtitle" />
    </OmtTypography>

    <OmtTypography v-if="tour.description" as="p1" mb="32px">
      <span v-html="tour.description" />
    </OmtTypography>

    <CBox position="fixed" bottom="0" width="calc(100vw - 40px)" max-width="1024px">
      <OmtButton @click="onGoToClick" w="100%">
        {{ tour.accessLabel }}
      </OmtButton>
      <CBox w="100%" :mb="iOS ? '32px' : '16px'">
        <OmtButton v-if="tour.nextLabel" @click="onNextClick" w="100%" variant="ghost">
          {{ tour.nextLabel }}
        </OmtButton>
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { ROTA_BASE, ROTA_WELCOME_ONBOARD_TOUR } from "@/utils/perfilRouteUtil";

export default {
  name: "WelcomeItem",
  mounted() {
    this.initTour(this.$route);
  },
  data() {
    return {
      tour: null
    };
  },
  watch: {
    $route(route) {
      this.initTour(route);
    }
  },
  computed: {
    ...mapState("myAccount", ["welcomeOnBoardTour", "welcomeOnBoardTourStep"]),
    iOS() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    }
  },
  methods: {
    ...mapActions("myAccount", ["submitWelcomeOnBoardTour"]),
    ...mapMutations("myAccount", ["SET_WELCOME_ONBOARD_TOUR_STEP"]),
    initTour(route) {
      try {
        this.$root.$emit("show-loading");
        if (this.welcomeOnBoardTour?.length && "idx" in route.params) {
          const id = Number(this.$route.params.idx);
          let prev = null;
          let current = null;
          for (let i = 0; i < this.welcomeOnBoardTour.length; i++) {
            if (this.welcomeOnBoardTour[i].id === id) {
              current = this.welcomeOnBoardTour[i];
              break;
            } else {
              prev = this.welcomeOnBoardTour[i];
            }
          }
          if (prev) {
            this.$root.$emit("change-back-route", `${ROTA_WELCOME_ONBOARD_TOUR}/${prev.id}`);
          } else {
            this.$root.$emit("change-back-route", ROTA_BASE);
            this.SET_WELCOME_ONBOARD_TOUR_STEP(0);
          }

          if (current) {
            this.tour = current;
            this.$root.$emit("change-title-label", current.title);
            if (!this.welcomeOnBoardTourStep && current.order > 0) {
              this.SET_WELCOME_ONBOARD_TOUR_STEP(current.id);
            }
          }
        }
      } finally {
        this.$root.$emit("hide-loading");
        if (!this.tour) this.$router.replace(ROTA_BASE);
      }
    },
    async onGoToClick() {
      await this.submitWelcomeOnBoardTour({ id: this.tour.id, option: true });
      if (this.tour.order > 0) {
        this.$router.push(this.tour.pathTo);
        return;
      }
      const nextBoard = this.welcomeOnBoardTour.find(b => !b.currentResponse);
      if (nextBoard) {
        this.$router.push(`${ROTA_WELCOME_ONBOARD_TOUR}/${nextBoard.id}`);
        return;
      }
      this.$router.replace(ROTA_BASE);
    },
    async onNextClick() {
      await this.submitWelcomeOnBoardTour({ id: this.tour.id, option: false });
      if (this.tour.order === 0) {
        this.$router.replace(ROTA_BASE);
        return;
      }
      let indexOf = -1;
      for (let i = 0; i < this.welcomeOnBoardTour.length; i++) {
        if (this.welcomeOnBoardTour[i].id === this.tour.id) {
          indexOf = i;
          break;
        }
      }
      const nextIndex = indexOf + 1;
      if (nextIndex === 0 || nextIndex >= this.welcomeOnBoardTour.length) {
        this.$router.replace(ROTA_BASE);
      } else {
        this.$router.push(`${ROTA_WELCOME_ONBOARD_TOUR}/${this.welcomeOnBoardTour[nextIndex].id}`);
      }
    }
  }
};
</script>
<style scoped></style>

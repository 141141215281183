<template>
  <OmtGrid v-if="auth">
    <OmtRow>
      <OmtTypography as="h1-bold" mb="24px"> Protocolo {{ auth.protocoloANS }} </OmtTypography>
    </OmtRow>

    <OmtRow>
      <TextTile v-bind="{ title: 'Data da solicitação', description: auth.dtSolicitacao }" />
    </OmtRow>

    <OmtRow>
      <CDivider mt="14px" mb="16px" />
    </OmtRow>

    <OmtRow>
      <OmtTypography as="h2-bold" mb="16px"> Dados do beneficiário </OmtTypography>
    </OmtRow>

    <OmtRow :desktopEnd="5" mb="16px">
      <TextTile v-bind="{ title: 'Beneficiário', description: auth.dsBeneficiario }" />
    </OmtRow>

    <OmtRow :desktopEnd="5" mb="16px">
      <TextTile v-bind="{ title: 'Código beneficiário', description: auth.idBeneficiario }" />
    </OmtRow>

    <OmtRow :desktopEnd="5" mb="16px">
      <StatusTile v-bind="{ title: 'Status', color: colorSituacao, label: auth.dsSituacao }" />
    </OmtRow>

    <template v-if="pictures.length">
      <OmtRow>
        <CDivider mt="14px" mb="16px" />
      </OmtRow>

      <OmtRow>
        <OmtTypography as="h2-bold" mb="8px"> Imagens enviadas </OmtTypography>
      </OmtRow>

      <OmtRow>
        <OmtTypography as="p1" mb="16px"> Pedidos médicos e relatórios </OmtTypography>
      </OmtRow>

      <OmtRow>
        <CGrid
          w="100%"
          :templateColumns="['repeat(auto-fill, minmax(74px, 1fr))', 'repeat(auto-fill, minmax(134px, 1fr))']"
          gap="6"
        >
          <ImagePreviewer v-for="(picture, index) in pictures" :key="index" v-bind="picture" />
        </CGrid>
      </OmtRow>
    </template>
  </OmtGrid>
</template>
<script>
import TextTile from "@/components/Molecules/TextTile";
import StatusTile from "@/components/Molecules/StatusTile";
import ImagePreviewer from "@/components/Molecules/ImagePreviewer";
import { mapState, mapActions } from "vuex";

export default {
  name: "AuthDetail",
  props: ["idx"],
  components: {
    TextTile,
    StatusTile,
    ImagePreviewer
  },
  data() {
    return {
      auth: {},
      authImgs: [],
      pictures: []
    };
  },
  async mounted() {
    if (!this.idx) {
      this.$router.replace({ name: "auths" });
      return;
    }
    try {
      await this.$root.$emit("show-loading");
      if (!this.auths || this.auths.length === 0) {
        await this.listAuths();
      }
      const id = Number.parseInt(this.idx);
      this.auth = this.auths.find(a => a.idChamado === id);
      if (!this.auth) {
        this.$router.replace({ name: "auths" });
        return;
      }
      if (this.auth.idAutorizacao > 0) {
        await this.$router.replace({ name: "auths.detail", params: { idx: this.auth.idAutorizacao } });
      }
      const allAuthDocs = await this.fetchAuthDocuments({ auth: 0, ticket: this.auth.idChamado });
      this.authImgs = allAuthDocs.filter(
        d => d.tipo === "gif" || d.tipo === "png" || d.tipo === "jpg" || d.tipo === "jpeg"
      );
      this.loadPictures();
    } catch {
      this.$router.replace({ name: "auths" });
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  computed: {
    ...mapState("auths", ["auths"]),
    isMobile() {
      const android = navigator.userAgent.match(/Android/i);
      const iPhone = navigator.userAgent.match(/iPhone|iPad|iPod/i);
      const windowsPhone = navigator.userAgent.match(/IEMobile/i);
      return android || iPhone || windowsPhone;
    },
    colorSituacao() {
      const colors = {
        A: "var(--colors-light-primary-navy-blue)",
        C: "var(--colors-light-support-error)",
        D: "var(--colors-light-support-error)",
        L: "var(--colors-light-support-success)",
        P: "var(--colors-light-support-warning)",
        R: "var(--colors-light-support-error)",
        T: "var(--colors-light-primary-gray2)"
      };
      const tpStatus = this.auth.tpSituacao;
      return tpStatus in colors ? colors[tpStatus] : "var(--colors-light-primary-gray2)";
    }
  },
  methods: {
    ...mapActions("auths", ["getAuthFileList", "fetchAuthDocuments", "listAuths"]),
    ...mapActions("myAccount", ["fetchImage"]),
    loadPictures() {
      if (!this.auth.idChamado) return;
      if (!this.authImgs?.length) return;
      this.authImgs.forEach(p =>
        this.fetchImage({ documento: p.id, original: false }).then(source =>
          this.pictures.push({
            id: p.id,
            source: source,
            loadModalImage: () => this.loadPicture(p.id)
          })
        )
      );
    },
    async loadPicture(id) {
      return await this.fetchImage({ documento: id, original: true });
    },
    downloadAuth(url) {
      window.location = url;
    }
  }
};
</script>

<template>
  <div class="container">
    <PasswordInputField label="Senha" :model="$v.pass" :password="true" />
    <PasswordInputField label="Repetir Senha" :model="$v.pass2" :password="true" />

    <CBox>
      <OmtTypography as="h3-semi-bold" color="var(--colors-light-primary-gray2)" mb="16px"
        >Sua senha deve conter:</OmtTypography
      >
      <ValidationRuleItem :isValid="$v.pass.$model !== '' && $v.pass.minLength" rule="Pelo menos 8 caracteres." />
      <ValidationRuleItem :isValid="$v.pass.hasUpperCase" rule="Pelo menos 1 letra maiúscula (A-Z)." />
      <ValidationRuleItem :isValid="$v.pass.hasNumber" rule="No mínimo 1 número (0-9)." />
      <ValidationRuleItem :isValid="$v.pass.hasSpecialChars" rule="1 caractere especial (@#$%&)." />
      <ValidationRuleItem
        :isValid="$v.pass2.$model !== '' && $v.pass2.sameAsPassword"
        rule="As senhas devem ser iguais."
      />
    </CBox>
  </div>
</template>

<script>
import { required, numeric, sameAs, minLength } from "vuelidate/lib/validators";
import formValidations from "@/utils/formValidations";
import ValidationRuleItem from "./ValidationRuleItem.vue";
import PasswordInputField from "./PasswordInputField.vue";

const { hasUpperCase, hasNumber, hasSpecialChars } = formValidations;

export default {
  name: "Password",
  props: [""],
  components: {
    ValidationRuleItem,
    PasswordInputField
  },
  data: function () {
    return {
      pass: "",
      pass2: "",
      passwordShow: false,
      repasswordShow: false,
      validPassword: false
    };
  },
  validations: {
    code: { required, numeric },
    pass: {
      required,
      minLength: minLength(8),
      hasUpperCase,
      hasNumber,
      hasSpecialChars
    },
    pass2: {
      required,
      sameAsPassword: sameAs("pass"),
      minLength: minLength(8),
      hasUpperCase,
      hasNumber,
      hasSpecialChars
    }
  },
  watch: {
    pass: function () {
      this.validPassword = !this.$v.$anyError;
    },
    pass2: function () {
      this.validPassword = !this.$v.$anyError;
    },
    validPassword: function () {
      this.$emit("passwordChange", { valid: this.validPassword, password: this.pass });
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
</style>

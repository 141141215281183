import Vue from "vue";
import {
  AceiteTermoSimualacaoCopay,
  BuscarEnderecoReferenciaV2,
  BuscarPrestadorSimulacaoCopay,
  ExameGrupoCopay,
  ListarExameSimulacaoCopay,
  ObterExamesProcuradosSimulacaoCopay,
  ObterLimiteExames,
  ObterModeloCopay,
  ObterPrestadoresProcuradosSimulacaoCopay,
  ObterSimulacaoCopay,
  ObterSimulacoesCopay,
  ObterTermoSimualacaoCopay,
  PodeSimularCopay,
  SimularCopay,
  SinonimosExameCopay
} from "@/api/redeCredenciada";
import _ from "lodash";
import { checkUpdatePermission } from "@/utils/checkReadonlyUtil";

const initialState = {
  allowSimulations: false,
  activeSort: "",
  sorts: ["Mais recentes", "Mais antigos", "Últimos 30 dias"],
  simulations: [],
  initialSimulations: [],
  simulationValuesDateRef: "",
  simulationCreationDate: "",
  terms: {},
  mostSearchedExamsTerms: [],
  mostSearchedExams: [],
  searchedExams: [],
  selectedExams: [],
  address: {},
  mostSearchedLaboratoriesTerms: [],
  mostSearchedLaboratories: [],
  searchedLaboratories: [],
  selectedLaboratories: [],
  copayModel: [],
  examsLimit: 0
};

export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  actions: {
    async fetchBaseAddress(context, { type }) {
      if (!type) type = "R";
      if (context.state.address?.type === type) return;
      const result = await BuscarEnderecoReferenciaV2({
        vinculo: context.rootState.saude.vinculo,
        tipoEndereco: type
      });
      const address = result.Data?.CEP ? { type, infos: result.Data } : {};
      context.commit("SET_PROP", { payload: address, prop: "address" });
    },
    async fetchCopayModel(context) {
      if (context.state.copayModel?.models?.length) return;
      const resp = await ObterModeloCopay({
        vinculo: context.rootState.saude.vinculo
      });
      if (resp?.Data?.Modelos?.length) {
        const copayModel = resp?.Data?.Modelos.map(m => ({
          icon: m.Icone,
          title: m.Nome,
          values: (m.Valores ?? []).map(v => ({
            label: v.Descricao,
            affiliate: v.Prestador,
            value: v.ValorPercentual,
            limit: v.Limite
          }))
        }));
        context.commit("SET_PROP", { payload: copayModel, prop: "copayModel" });
      }
    },
    async fetchPermition(context) {
      const regra = await PodeSimularCopay({
        vinculo: context.rootState.saude.vinculo
      });
      context.commit("SET_PROP", { payload: regra?.Data ?? false, prop: "allowSimulations" });
    },
    async acceptTermsCopay(context) {
      context.commit("CLEAN_SIMULATION");
      await AceiteTermoSimualacaoCopay({
        vinculo: context.rootState.saude.vinculo
      });
    },
    async fetchTermsCopay(context) {
      if (context.state.terms?.text) return;
      const termos = await ObterTermoSimualacaoCopay({
        vinculo: context.rootState.saude.vinculo
      });
      if (termos?.Data?.Texto && termos?.Data?.Titulo) {
        context.commit("SET_PROP", { payload: { title: termos.Data.Titulo, text: termos.Data.Texto }, prop: "terms" });
      }
    },
    async fetchSimulations(context) {
      const simulacoes = await ObterSimulacoesCopay({
        vinculo: context.rootState.saude.vinculo
      });
      if (simulacoes?.Data?.length) {
        const simulations = simulacoes.Data.map(s => ({
          id: s.Id,
          title: s.Titulo,
          date: s.DataSimulacao,
          qty: s.ExamesSimulados
        }));
        context.commit("SET_PROP", { payload: _.cloneDeep(simulations), prop: "simulations" });
        context.commit("SET_PROP", { payload: _.cloneDeep(simulations), prop: "initialSimulations" });
      }
    },
    async fetchSimulationDetail(context, { id }) {
      const simulacao = await ObterSimulacaoCopay({
        vinculo: context.rootState.saude.vinculo,
        id
      });
      if (simulacao?.Data?.Prestadores?.length && simulacao?.Data?.Exames?.length) {
        const selectedLaboratories = simulacao.Data.Prestadores.map(p => ({
          CodigoRede: p.CodigoRede,
          CodigoPrestador: p.CodigoPrestador,
          SrEndereco: p.SrEndereco,
          name: p.DescricaoPrestador,
          distance: p.Distancia ? `${Math.round(p.Distancia * 100) / 100}km`.replaceAll(".", ",") : "",
          price: p.ValorFormatado,
          logo: p.Logo ?? "https://picsum.photos/24/24",
          address: p.EnderecoCompleto
        }));
        context.commit("SET_PROP", { payload: selectedLaboratories, prop: "selectedLaboratories" });
        const selectedExams = simulacao.Data.Exames.map(e => ({
          name: e
        }));
        context.commit("SET_PROP", { payload: selectedExams, prop: "selectedExams" });
        context.commit("SET_PROP", { payload: simulacao.Data.DataTabelaVigente, prop: "simulationValuesDateRef" });
        context.commit("SET_PROP", { payload: simulacao.Data.DataSimulacao, prop: "simulationCreationDate" });
        return true;
      } else {
        context.commit("CLEAN_SIMULATION");
        return false;
      }
    },
    triggerExam(context, { isActive, name }) {
      if (isActive && context.state.selectedExams.length >= context.state.examsLimit) {
        return false;
      }

      const exam = context.state.searchedExams.find(e => e.name === name);
      if (isActive) {
        context.commit("SET_PROP", {
          prop: "selectedExams",
          payload: [
            ...context.state.selectedExams,
            {
              id: exam.id,
              name: exam.name,
              group: exam.group ?? ""
            }
          ]
        });
      } else {
        const selectedExams = context.state.selectedExams.filter(e => e.name !== name);
        context.commit("SET_PROP", { prop: "selectedExams", payload: [...selectedExams] });
      }

      context.dispatch("checkActiveExam");
      context.commit("CLEAN_LABS");
      return true;
    },
    checkActiveExam(context) {
      const searchedExams = JSON.parse(JSON.stringify(context.state.searchedExams));
      searchedExams.forEach(e => {
        if (e.options?.length) {
          e.options.forEach(o => (o.isActive = !!context.state.selectedExams.find(s => s.name === o.name)));
          e.isActive = !!context.state.selectedExams.find(s => s.name === e.name) || e.options.find(o => o.isActive);
        } else {
          e.isActive = !!context.state.selectedExams.find(s => s.name === e.name);
        }
      });
      context.commit("SET_PROP", { prop: "searchedExams", payload: searchedExams });
    },
    async searchExamGroup(context, { exam }) {
      if (!exam || !exam?.group || exam.options?.length) return exam;
      const result = await ExameGrupoCopay({
        vinculo: context.rootState.saude.vinculo,
        grupo: exam.group
      });
      if (result?.Data?.length) {
        const searchedExams = JSON.parse(JSON.stringify(context.state.searchedExams));
        exam = searchedExams.find(e => e.group === exam.group);
        exam.options = result.Data.map(r => ({ id: r.Codigo, label: r.Nome, isActive: false }));
        context.commit("SET_PROP", { prop: "searchedExams", payload: searchedExams });
        context.dispatch("checkActiveExam");
      }
      return exam;
    },
    async searchExamSynonym(context, { exam }) {
      if (!exam || !exam.id || !exam.hasSynonym || !!exam.description) return exam;
      const result = await SinonimosExameCopay({
        vinculo: context.rootState.saude.vinculo,
        exame: exam.id
      });
      if (result?.Data) {
        const searchedExams = JSON.parse(JSON.stringify(context.state.searchedExams));
        exam = searchedExams.find(e => e.id === exam.id);
        exam.description = result.Data;
        context.commit("SET_PROP", { prop: "searchedExams", payload: searchedExams });
      }
      return exam;
    },
    async searchExam(context, { search }) {
      if (!search) {
        context.commit("SET_PROP", { prop: "searchedExams", payload: context.state.mostSearchedExams });
        context.dispatch("checkActiveExam");
        return;
      }
      const result = await ListarExameSimulacaoCopay({
        vinculo: context.rootState.saude.vinculo,
        exame: search
      });

      if (result?.Data?.length) {
        const exames = result.Data.map(e => ({
          id: e.Codigo,
          name: e.Nome,
          isActive: false,
          hasSynonym: e.UsaSinonimos,
          description: e.DescricaoSinonimos,
          group: e.Grupo,
          options: (e.ExamesGrupo ?? []).map(g => ({ id: g.Codigo, label: g.Nome, isActive: false }))
        }));
        context.commit("SET_PROP", { prop: "searchedExams", payload: exames });
        context.dispatch("checkActiveExam");
      } else {
        context.commit("SET_PROP", { prop: "searchedExams", payload: [] });
      }
    },
    async fetchMostSearchedExams(context) {
      if (context.state.mostSearchedExams?.length) {
        context.dispatch("searchExam", { search: "" });
        return;
      }
      const result = await ObterExamesProcuradosSimulacaoCopay({
        vinculo: context.rootState.saude.vinculo,
        incluirTermos: false
      });

      if (result?.Data?.Termos?.length || result?.Data?.Exames?.length) {
        const exames = result.Data.Exames.map(e => ({
          id: e.Codigo,
          name: e.Nome,
          isActive: !!context.state.selectedExams.find(s => s.id === e.Codigo),
          hasSynonym: e.UsaSinonimos,
          description: e.DescricaoSinonimos,
          group: e.Grupo,
          options: (e.ExamesGrupo ?? []).map(g => ({ id: g.Codigo, label: g.Nome, isActive: false }))
        }));
        context.commit("SET_PROP", { prop: "searchedExams", payload: exames });
        context.commit("SET_PROP", { prop: "mostSearchedExamsTerms", payload: ["Todos", ...result.Data.Termos] });
        context.commit("SET_PROP", { prop: "mostSearchedExams", payload: exames });
      } else {
        context.commit("SET_PROP", { prop: "searchedExams", payload: [] });
        context.commit("SET_PROP", { prop: "mostSearchedExamsTerms", payload: [] });
        context.commit("SET_PROP", { prop: "mostSearchedExams", payload: [] });
      }
    },
    triggerLaboratory(context, { isActive, lab }) {
      if (!lab.CodigoPrestador || !lab.SrEndereco) return;
      const selectedLaboratories = context.state.selectedLaboratories.filter(
        l => !(l.CodigoPrestador === lab.CodigoPrestador && l.SrEndereco === lab.SrEndereco)
      );
      if (isActive) {
        selectedLaboratories.push(JSON.parse(JSON.stringify(lab)));
      }
      context.commit("SET_PROP", { prop: "selectedLaboratories", payload: selectedLaboratories });
      context.dispatch("checkActiveLaboratory");
    },
    checkActiveLaboratory(context) {
      const searchedLaboratories = JSON.parse(JSON.stringify(context.state.searchedLaboratories));
      searchedLaboratories.forEach(lab => {
        lab.isActive = !!context.state.selectedLaboratories.find(
          l => l.CodigoPrestador === lab.CodigoPrestador && l.SrEndereco === lab.SrEndereco
        );
      });
      context.commit("SET_PROP", { prop: "searchedLaboratories", payload: searchedLaboratories });
    },
    async searchLaboratory(context, { search }) {
      if (!search) {
        context.commit("SET_PROP", { prop: "searchedLaboratories", payload: context.state.mostSearchedLaboratories });
        context.dispatch("checkActiveLaboratory");
        return;
      }
      const exames = context.state.selectedExams.map(e => e.id);
      const result = await BuscarPrestadorSimulacaoCopay({
        vinculo: context.rootState.saude.vinculo,
        exames,
        tipoEndereco: context.state.address?.type,
        nome: search
      });

      if (result?.Data?.Prestadores?.length) {
        const laboratories = result.Data.Prestadores.map(p => ({
          CodigoRede: p.CodigoRede,
          CodigoPrestador: p.CodigoPrestador,
          SrEndereco: p.SrEndereco,
          name: p.DescricaoPrestador,
          distance: p.Distancia ? `${Math.round(p.Distancia * 100) / 100}km`.replaceAll(".", ",") : "",
          price: p.ValorFormatado,
          logo: p.Logo ?? "https://picsum.photos/24/24",
          address: p.EnderecoCompleto
        }));
        context.commit("SET_PROP", { prop: "searchedLaboratories", payload: laboratories });
        context.dispatch("checkActiveLaboratory");
      } else {
        context.commit("SET_PROP", { prop: "searchedLaboratories", payload: [] });
      }
    },
    async fetchMostSearchedLaboratories(context) {
      if (context.state.mostSearchedLaboratories?.length) {
        context.dispatch("searchLaboratory", { search: "" });
        return;
      }
      const exames = context.state.selectedExams.map(e => e.id);
      const result = await ObterPrestadoresProcuradosSimulacaoCopay({
        vinculo: context.rootState.saude.vinculo,
        exames,
        tipoEndereco: context.state.address?.type,
        incluirTermos: false
      });

      if (result?.Data?.Termos?.length || result?.Data?.Prestadores?.length) {
        const laboratories = result.Data.Prestadores.map(p => ({
          CodigoRede: p.CodigoRede,
          CodigoPrestador: p.CodigoPrestador,
          SrEndereco: p.SrEndereco,
          name: p.DescricaoPrestador,
          distance: p.Distancia ? `${Math.round(p.Distancia * 100) / 100}km`.replaceAll(".", ",") : "",
          price: p.ValorFormatado,
          logo: p.Logo ?? "https://picsum.photos/24/24",
          address: p.EnderecoCompleto
        }));
        context.commit("SET_PROP", { prop: "searchedLaboratories", payload: laboratories });
        context.commit("SET_PROP", {
          prop: "mostSearchedLaboratoriesTerms",
          payload: ["Todos", ...result.Data.Termos]
        });
        context.commit("SET_PROP", { prop: "mostSearchedLaboratories", payload: laboratories });
      } else {
        context.commit("SET_PROP", { prop: "searchedLaboratories", payload: [] });
        context.commit("SET_PROP", { prop: "mostSearchedLaboratoriesTerms", payload: [] });
        context.commit("SET_PROP", { prop: "mostSearchedLaboratories", payload: [] });
      }
    },
    async submitSimulation(context) {
      checkUpdatePermission();
      const exames = context.state.selectedExams.map(e => ({
        Codigo: e.id,
        Nome: e.name
      }));
      const prestadores = context.state.selectedLaboratories.map(p => ({
        CodigoPrestador: p.CodigoPrestador,
        SrEndereco: p.SrEndereco
      }));
      const simulacao = await SimularCopay({ vinculo: context.rootState.saude.vinculo, exames, prestadores });
      if (!simulacao) return "Não foi possível realizar a simulação";
      if (simulacao instanceof Error) return simulacao.message;
      if (simulacao?.Data?.Prestadores?.length) {
        const selectedLaboratories = JSON.parse(JSON.stringify(context.state.selectedLaboratories));
        selectedLaboratories.forEach(p => {
          p.price = simulacao.Data.Prestadores.find(
            r => r.CodigoPrestador === p.CodigoPrestador && r.SrEndereco === p.SrEndereco
          )?.ValorFormatado;
        });
        context.commit("SET_PROP", { payload: selectedLaboratories, prop: "selectedLaboratories" });
        context.commit("SET_PROP", { payload: simulacao.Data.DataTabelaVigente, prop: "simulationValuesDateRef" });
        context.commit("SET_PROP", { payload: simulacao.Data.DataSimulacao, prop: "simulationCreationDate" });
        return "";
      } else {
        return "Não foi possível realizar a simulação";
      }
    },
    async fetchExamsLimit(context) {
      const limite = await ObterLimiteExames({ vinculo: context.rootState.saude.vinculo });
      if (limite.Data) {
        context.commit("SET_PROP", { prop: "examsLimit", payload: limite.Data });
      }
    }
  },
  mutations: {
    LOGOUT_USER: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    RESET_CONTEXT: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    SET_PROP(state, { payload, prop }) {
      Vue.set(state, prop, payload);
    },
    SORT_SIMULATIONS(state, sort) {
      state.activeSort = sort;

      if (!sort) {
        state.simulations = _.cloneDeep(state.initialSimulations);
        return;
      }

      if (sort === "Mais recentes") {
        state.simulations.sort(
          (a, b) => new Date(b.date.split("/").reverse().join("/")) - new Date(a.date.split("/").reverse().join("/"))
        );
      } else if (sort === "Mais antigos") {
        state.simulations.sort(
          (a, b) => new Date(a.date.split("/").reverse().join("/")) - new Date(b.date.split("/").reverse().join("/"))
        );
      } else if (sort === "Últimos 30 dias") {
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        state.simulations = state.simulations.filter(
          simulation => new Date(simulation.date.split("/").reverse().join("/")) >= thirtyDaysAgo
        );
      }
    },
    CLEAN_SORTS(state) {
      state.simulations = _.cloneDeep(state.initialSimulations);
      state.activeSort = "";
    },
    CLEAN_LABS(state) {
      state.selectedLaboratories = [];
      state.searchedLaboratories = [];
      state.mostSearchedLaboratoriesTerms = [];
      state.mostSearchedLaboratories = [];
    },
    CLEAN_SIMULATION(state) {
      state.selectedExams = [];
      state.searchedExams = [];
      state.mostSearchedExamsTerms = [];
      state.mostSearchedExams = [];
      state.selectedLaboratories = [];
      state.searchedLaboratories = [];
      state.mostSearchedLaboratoriesTerms = [];
      state.mostSearchedLaboratories = [];
    }
  }
};

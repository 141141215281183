import { render, staticRenderFns } from "./sms.vue?vue&type=template&id=035b019e&scoped=true&"
import script from "./sms.vue?vue&type=script&lang=js&"
export * from "./sms.vue?vue&type=script&lang=js&"
import style0 from "./sms.vue?vue&type=style&index=0&id=035b019e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "035b019e",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CBox: require('@chakra-ui/vue').CBox, CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CFlex: require('@chakra-ui/vue').CFlex, CModalBody: require('@chakra-ui/vue').CModalBody, CModalContent: require('@chakra-ui/vue').CModalContent, CModal: require('@chakra-ui/vue').CModal})

import Vue from "vue";
import {
  ObterLogradouros,
  ObterParametrosEndereco,
  ObterFormasContato,
  ObterResumoFormasContato,
  SolicitarAlteracaoGeral,
  ConsultarRequisicaoAlteracao,
  ValidarAlteracaoGeral,
  ObterMunicipios
} from "@/api/formasContato";
import { ExisteChamadoPendenteProcessamento } from "@/api/atendimento";
import { checkUpdatePermission } from "@/utils/checkReadonlyUtil";

const initialState = {
  paramsAddress: {},
  contacts: [],
  resumeContacts: [],
  isBusinessAccount: false,
  changeRequest: {},
  changesResume: {},
  contactsToReceiveChanges: {},
  existChangeRequest: false,
  newAddress: null,
  newEmails: null,
  newPhones: null
};

// vuex module
export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  actions: {
    async fetchAddressInfos(context, { cep }) {
      return await ObterLogradouros({
        vinculo: context.rootState.saude.vinculo,
        cep
      });
    },
    async fetchCities(context, { uf }) {
      return await ObterMunicipios({
        vinculo: context.rootState.saude.vinculo,
        uf
      });
    },
    async fetchParamsAddress(context) {
      const paramsAddress = await ObterParametrosEndereco({
        vinculo: context.rootState.saude.vinculo
      });
      context.commit("SET_NEW_STATE", {
        key: "paramsAddress",
        value: paramsAddress
      });
    },
    async fetchContacts(context) {
      try {
        const contacts = await ObterFormasContato({
          vinculo: context.rootState.saude.vinculo
        });

        context.commit("SET_NEW_STATE", {
          key: "isBusinessAccount",
          value: contacts.Empresarial
        });

        context.commit("SET_NEW_STATE", {
          key: "contacts",
          value: contacts
        });
      } catch (error) {
        throw Error(error);
      }
    },
    async fetchResumeContacts(context) {
      try {
        const contacts = await ObterResumoFormasContato({
          vinculo: context.rootState.saude.vinculo
        });

        delete contacts.Data.Emails.Autenticacao;
        delete contacts.Data.Endereco.Autenticacao;

        const tels = [];

        contacts.Data.Telefones.forEach((tel, id) => {
          delete tel.Autenticacao;

          const countryCodeRegex = /^\+(\d+)\s*/;
          const match = tel.Numero.match(countryCodeRegex);

          let countryCode = "";

          if (match && match[1]) {
            countryCode = "+" + match[1];
          }

          tels[id] = {
            Numero: tel.Numero.replace(countryCodeRegex, ""),
            Tipo: tel.Tipo,
            countryCode
          };
        });

        contacts.Data.Telefones = tels;

        context.commit("SET_NEW_STATE", {
          key: "resumeContacts",
          value: contacts.Data
        });
      } catch (error) {
        throw Error(error);
      }
    },
    async validadeChanges(context) {
      const telefones =
        context.state.newPhones?.map(phone => ({
          Numero: `${phone.countryCode} ${phone.Numero}`,
          Tipo: phone.Tipo
        })) || [];
      const changes = await ValidarAlteracaoGeral({
        vinculo: context.rootState.saude.vinculo,
        emails: context.state.newEmails || [],
        endereco: context.state.newAddress,
        telefones
      });
      if (!changes)
        return {
          MsgValidacao: "Serviço temporariamente indisponível",
          Enderecos: [],
          Telefones: [],
          Emails: {}
        };
      if (changes instanceof Error)
        return {
          MsgValidacao: changes.message,
          Enderecos: [],
          Telefones: [],
          Emails: {}
        };

      context.commit("SET_NEW_STATE", {
        key: "changesResume",
        value: { ...changes }
      });
      return changes;
    },
    async createChangeRequest(context, { auth }) {
      checkUpdatePermission();

      const telefones =
        context.state.newPhones?.map(phone => ({
          Numero: `${phone.countryCode} ${phone.Numero}`,
          Tipo: phone.Tipo
        })) || [];
      const response = await SolicitarAlteracaoGeral({
        vinculo: context.rootState.saude.vinculo,
        usuario: context.rootState.saude.username,
        emails: context.state.newEmails || [],
        endereco: context.state.newAddress,
        telefones,
        autenticacao: auth
      });

      if (response instanceof Error) throw Error(response);

      if (!!response.Protocolo && !!response.UrlRequisicao) {
        context.commit("SET_NEW_STATE", {
          key: "changeRequest",
          value: {
            Protocolo: response.Protocolo,
            UrlRequisicao: response.UrlRequisicao,
            protocolHash: response.UrlRequisicao.split("/")[response.UrlRequisicao.split("/").length - 1]
          }
        });

        context.commit("SET_NEW_STATE", {
          key: "contactsToReceiveChanges",
          value: {
            emailContato: context.rootState.bankAccount.emails[0],
            telefoneContato: context.rootState.bankAccount.phones[0]
          }
        });
      }
    },
    async fetchChangesResume(context, { chave }) {
      const changes = await ConsultarRequisicaoAlteracao({ chave });
      context.commit("SET_NEW_STATE", {
        key: "changesResume",
        value: { ...changes }
      });
    },
    async validateActiveTicket(context) {
      if (!context.rootState.saude.vinculo) return false;

      const existChangeRequest = await ExisteChamadoPendenteProcessamento({
        vinculo: context.rootState.saude.vinculo,
        beneficiario: context.rootState.saude.vinculo,
        servico: 5
      });

      context.commit("SET_NEW_STATE", { key: "existChangeRequest", value: existChangeRequest.Data });
    }
  },
  mutations: {
    SET_NEW_STATE: (state, { key, value }) => Vue.set(state, key, value),
    LOGOUT_USER: state => Object.keys(state).forEach(key => (state[key] = initialState[key])),
    RESET_CONTEXT: state => Object.keys(state).forEach(key => (state[key] = initialState[key])),
    RESET_NEW_CONTACTS: state => {
      ["newAddress", "newEmails", "newPhones"].forEach(key => {
        state[key] = null;
      });
    }
  },
  getters: {
    hasNewChanges(state) {
      return state.newAddress || state.newEmails || state.newPhones;
    }
  }
};

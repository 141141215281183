const baseURL = process.env.VUE_APP_SERVER_URL;

export async function Login({ username, password }) {
  const hash = window.$jse.encrypt(password);
  return window.$axios
    .post({
      url: `${baseURL}/Login`,
      data: {
        grant_type: "password",
        username,
        password: hash,
        TipoAcesso: 1
      },
      contentType: "application/x-www-form-urlencoded"
    })
    .then(resp => {
      window.$axios.setAuthToken(resp.data.access_token);
      return resp.data;
    })
    .catch(error => {
      window.$log.error("Login", error.message);
      return error;
    });
}

export async function RefreshToken({ token }) {
  return window.$axios
    .post({
      url: baseURL + "/Login",
      data: {
        grant_type: "refresh_token",
        refresh_token: token
      },
      contentType: "application/x-www-form-urlencoded"
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("RefreshToken", error.message);
      return error;
    });
}

export async function ObterVinculos({ usuario }) {
  return window.$axios
    .get({
      url: `${baseURL}/vinculos`,
      query: {
        login: null,
        usuario
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("Vinculos", error.message);
      return error;
    });
}

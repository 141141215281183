<template>
  <CBox v-if="exibir" :display="['block', 'none']">
    <div class="footer-fixed-margin"></div>
    <div class="footer-fixed">
      <CBox borderTop="0.5px solid var(--colors-light-secondary-divider)" padding="12px">
        <CBox v-if="rotafluxoBemVindoAtivo" textAlign="center">
          <router-link :to="rotafluxoBemVindoAtivo">
            <OmtButton w="100%"> Próxima Etapa </OmtButton>
          </router-link>
        </CBox>
        <CGrid v-else template-columns="repeat(3, 1fr)" gap="3" textAlign="center">
          <CBox v-if="home">
            <router-link to="/">
              <OmtIcons cursor="pointer" name="house" />
              <OmtTypography cursor="pointer" as="p2">Home</OmtTypography>
            </router-link>
          </CBox>
          <CBox v-if="services">
            <router-link to="/services">
              <OmtIcons cursor="pointer" name="grid" />
              <OmtTypography cursor="pointer" as="p2">Serviços</OmtTypography>
            </router-link>
          </CBox>
          <CBox v-if="whatsapp">
            <router-link to="/whatsapp">
              <OmtIcons cursor="pointer" name="whatsapp" />
              <OmtTypography cursor="pointer" as="p2">Whatsapp</OmtTypography>
            </router-link>
          </CBox>
        </CGrid>
      </CBox>
    </div>
    <div />
  </CBox>
</template>

<script>
import { mapState } from "vuex";
import { ROTA_OPTIN, ROTA_WELCOME_ONBOARD_TOUR } from "@/utils/perfilRouteUtil";

export default {
  props: {
    home: {
      type: Boolean,
      default: true
    },
    services: {
      type: Boolean,
      default: true
    },
    whatsapp: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState("myAccount", ["welcomeOnBoardTour", "welcomeOnBoardTourStep"]),
    exibir() {
      const rota = this.$route.path;
      if (rota.startsWith(ROTA_OPTIN)) return false;
      if (rota.startsWith(ROTA_WELCOME_ONBOARD_TOUR)) return false;
      return true;
    },
    rotafluxoBemVindoAtivo() {
      if (!this.welcomeOnBoardTourStep || !this.exibir) return "";
      const tour = this.welcomeOnBoardTour ?? [];
      const nextTour = tour.find(b => !b.currentResponse);
      if (nextTour?.id) return `${ROTA_WELCOME_ONBOARD_TOUR}/${nextTour.id}`;
      return "";
    }
  }
};
</script>

<style scoped>
.footer-fixed {
  position: fixed;
  bottom: 0;
  left: 0px;
  right: 0px;
  z-index: 50;
  background: white;
}

@media all and (orientation: landscape) and (min-width: 1024px) {
  .footer-fixed {
    position: absolute;
  }
}

.footer-fixed-margin {
  height: 65px;
  clear: both;
}
</style>

/** Agrupa um array de objetos por determinada propriedade
 * Ex:
 * array = [{id:"1"}, {id:"2"}]
 * agruparPor(array, "id")
 */
export const agruparPor = (objetoArray, propriedade) =>
  objetoArray.reduce((acc, obj) => {
    const key = obj[propriedade];

    if (!acc[key]) acc[key] = [];
    acc[key].push(obj);

    return acc;
  }, {});

//DIAS DA SEMANA
const weekDays = () => [
  { name: "Domingo", initials: "Dom", value: 1 },
  { name: "Segunda", initials: "Seg", value: 2 },
  { name: "Terça", initials: "Ter", value: 3 },
  { name: "Quarta", initials: "Qua", value: 4 },
  { name: "Quinta", initials: "Qui", value: 5 },
  { name: "Sexta", initials: "Sex", value: 6 },
  { name: "Sabado", initials: "Sab", value: 7 }
];

//MESES DO ANO
const months = () => [
  { name: "Janeiro", initials: "Jan", value: 1 },
  { name: "Fevereiro", initials: "Fev", value: 2 },
  { name: "Março", initials: "Mar", value: 3 },
  { name: "Abril", initials: "Abr", value: 4 },
  { name: "Maio", initials: "Mai", value: 5 },
  { name: "Junho", initials: "Jun", value: 6 },
  { name: "Julho", initials: "Jul", value: 7 },
  { name: "Agosto", initials: "Ago", value: 8 },
  { name: "Setembro", initials: "Set", value: 9 },
  { name: "Outubro", initials: "Out", value: 10 },
  { name: "Novembro", initials: "Nov", value: 11 },
  { name: "Dezembro", initials: "Dez", value: 12 }
];

//ESTADOS DO BRASIL
const statesFromCountry = [
  { id: 1, initials: "AC", name: "Acre" },
  { id: 2, initials: "AL", name: "Alagoas" },
  { id: 3, initials: "AM", name: "Amazonas" },
  { id: 4, initials: "AP", name: "Amapá" },
  { id: 5, initials: "BA", name: "Bahia" },
  { id: 6, initials: "CE", name: "Ceará" },
  { id: 7, initials: "DF", name: "Distrito Federal" },
  { id: 8, initials: "ES", name: "Espírito Santo" },
  { id: 9, initials: "GO", name: "Goiás" },
  { id: 10, initials: "MA", name: "Maranhão" },
  { id: 11, initials: "MG", name: "Minas Gerais" },
  { id: 12, initials: "MS", name: "Mato Grosso do Sul" },
  { id: 13, initials: "MT", name: "Mato Grosso" },
  { id: 14, initials: "PA", name: "Pará" },
  { id: 15, initials: "PB", name: "Paraíba" },
  { id: 16, initials: "PE", name: "Pernambuco" },
  { id: 17, initials: "PI", name: "Piauí" },
  { id: 18, initials: "PR", name: "Paraná" },
  { id: 19, initials: "RJ", name: "Rio de Janeiro" },
  { id: 20, initials: "RN", name: "Rio Grande do Norte" },
  { id: 21, initials: "RO", name: "Rondônia" },
  { id: 22, initials: "RR", name: "Roraima" },
  { id: 23, initials: "RS", name: "Rio Grande do Sul" },
  { id: 24, initials: "SC", name: "Santa Catarina" },
  { id: 25, initials: "SE", name: "Sergipe" },
  { id: 26, initials: "SP", name: "São Paulo" },
  { id: 27, initials: "TO", name: "Tocantins" }
];

export const maskTel8Digit = ["(", /\d/, /\d/, ") ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
export const maskTel9Digit = ["(", /\d/, /\d/, ") ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

export function maskTelFull(value) {
  if (value.length <= 18)
    return [
      "+",
      "5",
      "5",
      " ",
      "(",
      /[0-9]/,
      /[0-9]/,
      ")",
      " ",
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      "-",
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/
    ];
  else
    return [
      "+",
      "5",
      "5",
      " ",
      "(",
      /[0-9]/,
      /[0-9]/,
      ")",
      " ",
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      "-",
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/
    ];
}

export default {
  agruparPor,
  weekDays,
  months,
  statesFromCountry,
  maskTelFull,
  maskTel8Digit,
  maskTel9Digit
};

<template>
  <omint-page-content>
    <div class="term-box">
      <OmtTypography as="h1-bold" mb="16px">Verificação de identidade</OmtTypography>
      <OmtTypography as="h3-semi-bold" mb="24px"
        >Para confirmar sua identidade enviaremos um código de verificação. Selecione o e-mail para
        recebê-lo.</OmtTypography
      >

      <OmtTypography as="h1-bold" mb="16px" v-if="emailsValidos.length > 0">E-mail:</OmtTypography>
      <OmtRadioGroup
        v-if="emailsOfuscados.length > 0"
        hasDivider
        :values="emailsOfuscados.map((e, i) => ({ id: i, label: e }))"
        :value.sync="checked"
        customValueKey="id"
        mb="40px"
        ml="1px"
      />

      <CBox borderRadius="8px" backgroundColor="var(--colors-light-primary-light-gray)" p="16px" mb="24px">
        <CFlex>
          <CBox>
            <OmtIcons mr="16px" size="xl" name="lightbulb" />
          </CBox>
          <OmtTypography as="h1-bold" mb="16px">Atenção:</OmtTypography>
        </CFlex>
        <OmtTypography as="p1" v-if="emailsValidos.length === 0">
          <b>Não identificamos um e-mail cadastrado. </b>
          Entre em contato com a central de atendimento Omint para orientações: <b>0800 726 4000</b>.
        </OmtTypography>
        <OmtTypography as="p1" v-else>
          Caso você não tenha acesso à este email, entre em contato com a central de atendimento Omint para orientações:
          <b>0800 726 4000.</b></OmtTypography
        >
      </CBox>
    </div>
    <OmtButton
      variant="solid"
      width="100%"
      height="50px"
      border="0.5px solid var(--colors-light-secondary-divider)"
      borderRadius="40px"
      data-cy="button-entrar"
      @click="handleClick()"
      :disabled="checked === null && emailsValidos.length > 0"
      mb="3"
    >
      <OmtTypography as="h1-bold" color="white" v-if="emailsValidos.length > 0">Enviar código</OmtTypography>
      <OmtTypography as="h1-bold" color="white" v-else>Ligar para a central Omint</OmtTypography>
    </OmtButton>
  </omint-page-content>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "MailCheck",
  mounted() {
    this.$root.$emit("change-title-label", "Confirmação de Identidade");
    this.emailsValidos = this.emails?.length ? this.emails.filter(e => this.isValidEmail(e)) : [];
    this.emailsOfuscados = this.emailsValidos.map(e => this.ofuscateEmail(e)) ?? [];
  },
  data() {
    return {
      emailsValidos: [],
      emailsOfuscados: [],
      checked: null
    };
  },
  computed: {
    ...mapState("firstAccess", ["emails"])
  },
  methods: {
    ...mapActions("firstAccess", ["sendCode"]),
    handleClick: async function () {
      if (this.emailsValidos.length === 0) {
        window.open("tel:08007264000", "_self");
        return;
      }
      if (this.checked !== null) {
        await this.$root.$emit("show-loading");
        const errorMessage = await this.sendCode({ email: this.emailsValidos[this.checked] });
        await this.$root.$emit("hide-loading");
        if (errorMessage !== "") {
          Swal.fire({
            title: "Erro",
            text: errorMessage,
            confirmButtonText: "Ok",
            allowEscapeKey: false,
            allowOutsideClick: false
          });
          return;
        }
        await Swal.fire({
          title: "Sucesso",
          text: `Token enviado para ${this.emailsOfuscados[this.checked]}`,
          confirmButtonText: "OK",
          icon: "success"
        });
        await this.$router.push({ name: "first-access.code", params: { email: this.emailsValidos[this.checked] } });
      }
    },
    isValidEmail(email) {
      return email.includes("@") && !email.startsWith("@") && !email.endsWith("@");
    },
    ofuscateEmail(email) {
      if (!this.isValidEmail(email)) return email;
      const emailSplit = email.split("@");
      const comecoEmail = emailSplit[0];
      const fimEmail = emailSplit[1];
      if (comecoEmail.length > 3) {
        const hiddenChars = "•".repeat(comecoEmail.length - 3);
        const firstThreeChars = comecoEmail.substring(0, 3);
        return `${firstThreeChars}${hiddenChars}@${fimEmail}`;
      } else if (fimEmail.length > 3) {
        const hiddenChars = "•".repeat(fimEmail.length - 3);
        const firstThreeChars = fimEmail.substring(0, 3);
        return `${comecoEmail}@${firstThreeChars}${hiddenChars}`;
      } else {
        return email;
      }
    }
  }
};
</script>

<style scoped>
.term-box {
  height: calc(100vh - 180px);
  overflow-y: auto;
}
</style>

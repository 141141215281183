import BiometryRegister from "@/pages/biometry-register/index.vue";
import BiometryAuth from "@/pages/biometry-auth/index.vue";

const routes: Route[] = [
  {
    path: "/biometry/register",
    name: "biometry-register",
    component: BiometryRegister,
    meta: {
      backTo: "/"
    }
  },
  {
    path: "/biometry/auth",
    name: "biometry-auth",
    component: BiometryAuth,
    meta: {
      backTo: "/"
    }
  }
];

export default routes;

<template>
  <OmtRow class="footer-fixed-margin">
    <OmtRow class="fixed-button" :desktopStart="5" :desktopEnd="8">
      <slot></slot>
    </OmtRow>
  </OmtRow>
</template>
<script>
export default {
  name: "FixedButtonRow"
};
</script>
<style scoped>
.fixed-button {
  position: fixed;
  left: 0;
  right: 0;
  padding: 6px;
  background-color: white;
  justify-content: center;
  max-width: 1024px;
  width: initial;
  bottom: 66px;
  margin: initial;
}

@media screen and (min-width: 1024px) {
  .fixed-button {
    width: calc(100vw - 324px);
    bottom: 0;
    margin: 0 auto;
    background-color: transparent;
  }
}

@media all and (orientation: landscape) and ((max-width: 992px) or (max-height: 300px)) {
  .footer-fixed-margin {
    display: none;
  }

  .fixed-button {
    position: initial;
  }
}

.footer-fixed-margin {
  height: 60px;
  clear: both;
}
</style>

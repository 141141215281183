<template>
  <OmtLayoutHeaderFooter :footer="true" :header="{ titleLabel: 'Cadastro de biometria' }">
    <OmtLayoutContainer>
      <OmtRow>
        <BiometryAuthHeader />
      </OmtRow>

      <OmtRow mb="24px">
        <BiometryAuthCard />
      </OmtRow>

      <OmtRow>
        <BiometryCameraButton
          :disabled="alreadyRegistered"
          :confirmUser="true"
          @success="handleBiometryCameraSuccess"
          @error="handleBiometryCameraError"
          @resourceError="handleResourceError"
          @startLoading="$root.$emit('show-loading')"
          @endLoading="$root.$emit('hide-loading')"
        />
      </OmtRow>
    </OmtLayoutContainer>
    <FeedbackModal
      v-if="modalInfos"
      :protocol="modalInfos.protocol"
      :title="modalInfos.title"
      :cardTitle="modalInfos.cardTitle"
      :cardDescription="modalInfos.cardDescription"
      :cardIcon="modalInfos.cardIcon"
      :modalIconBind="modalInfos.modalIconBind"
      :buttonLabel="modalInfos.buttonLabel"
      :secondButtonLabel="modalInfos.secondButtonLabel"
      @buttonClick="modalInfos.buttonClick"
      @close="modalInfos.close"
      @secondButtonClick="modalInfos.secondButtonClick"
    />
    <LoadingModal v-if="process" :title="process.title" :description="process.description" :loading="true" />
  </OmtLayoutHeaderFooter>
</template>

<script>
import LoadingModal from "@/components/Organisms/LoadingModal";
import BiometryCameraButton from "@/components/Organisms/Biometry/BiometryCameraButton";
import BiometryAuthHeader from "@/components/Organisms/Biometry/BiometryAuthHeader.vue";
import FeedbackModal from "@/components/Molecules/FeedbackModal";
import { mapActions } from "vuex";
import BiometryAuthCard from "@/components/Organisms/Biometry/BiometryAuthCard";

export default {
  components: {
    LoadingModal,
    BiometryCameraButton,
    FeedbackModal,
    BiometryAuthCard,
    BiometryAuthHeader
  },
  data() {
    return {
      timer: null,
      modalToShow: "",
      process: null,
      alreadyRegistered: true,
      alreadyRegisteredProtocol: null,
      justRegisteredProtocol: null
    };
  },
  computed: {
    routeBack() {
      if ("routeBack" in this.$route.params) return this.$route.params.routeBack;
      return "/";
    },
    modals() {
      return {
        OnErrorModal: {
          title: "Não foi possível continuar",
          cardTitle: "Não foi possível detectar a câmera para captura",
          cardDescription:
            "Acesse os ajustes do seu smartphone e permita que o nosso app acesse sua câmera, ou realize a biometria em outro dispositivo.",
          cardIcon: "circle-exclamation",
          modalIconBind: {
            name: "circle-xmark",
            color: "var(--colors-light-primary-navy-blue)"
          },
          buttonLabel: "Entendi",
          buttonClick: this.handleCloseModalAndContinue,
          close: this.handleCloseModalAndContinue
        },
        SucessfullyRegistereBiometryModal: {
          title: "Biometria cadastrada com sucesso!",
          cardTitle: "Agora sua biometria está registrada no app",
          cardDescription: "Seu acesso, informações e transações já estão protegidos com o reconhecimento facial.",
          cardIcon: "face-viewfinder",
          modalIconBind: {
            name: "check-circle",
            color: "var(--colors-light-support-success)"
          },
          buttonLabel: "Concluir",
          buttonClick: this.handleCloseModalOnSucessfully,
          close: this.handleCloseModalOnSucessfully
        },
        AlreadyRegisteredProtocolInProcessModal: {
          title: "Biometria enviada para análise",
          cardTitle: "O tempo para análise pode ser de 30 minutos",
          cardDescription:
            "A análise ocorrerá:<ul style='margin-left: 20px;'><li style='padding-top: 20px;'>De segunda à sábado: 07h00 às 22h00.</li><li style='padding-top: 20px; margin-bottom: 20px;'>Domingos e Feriados: 09h00 às 21h00.</li></ul></p>Horário de Brasília.",
          cardIcon: "clock",
          modalIconBind: {
            name: "paper-plane-top",
            color: "var(--colors-light-primary-navy-blue)"
          },
          buttonLabel: "Entendi",
          buttonClick: this.handleCloseModalAndLeave,
          close: this.handleCloseModalAndLeave
        },
        AlreadyRegisteredProtocolLowerModal: {
          title: "Biometria enviada para análise",
          cardTitle: "Precisamos de mais algumas informações para validar sua biometria",
          cardDescription:
            "Para finalizar o processo entre em contato a nossa Central de Atendimento 24h pelo número: 0800 726 4001.",
          cardIcon: "circle-exclamation",
          modalIconBind: {
            name: "paper-plane-top",
            color: "var(--colors-light-primary-navy-blue)"
          },
          buttonLabel: "Entendi",
          buttonClick: this.handleCloseModalAndLeave,
          close: this.handleCloseModalAndLeave
        },
        AlreadyRegisteredProtocolZeroModal: {
          title: "Biometria não corresponde ao CPF cadastrado",
          cardTitle: "A biometria cadastrada deve pertencer ao associado logado",
          cardDescription:
            "Tente refazer a captura da sua biometria em um local com uma iluminação adequada e não se movimente durante o processo.",
          cardIcon: "circle-exclamation",
          modalIconBind: {
            name: "user-xmark",
            color: "var(--colors-light-primary-navy-blue)"
          },
          buttonLabel: "Refazer",
          secondButtonLabel: "Tentar mais tarde",
          buttonClick: this.handleCloseModalAndContinue,
          close: this.handleCloseModalAndContinue
        },
        AlreadyRegisteredProtocolNegativeModal: {
          title: "Não foi possível cadastrar a sua biometria",
          cardTitle: "Precisamos de mais informações para validar sua biometria",
          cardDescription:
            "Para finalizar o processo entre em contato a nossa Central de Atendimento 24h pelo número: 0800 726 4001.",
          cardIcon: "circle-exclamation",
          modalIconBind: {
            name: "circle-xmark",
            color: "var(--colors-light-primary-navy-blue)"
          },
          buttonLabel: "Entendi",
          buttonClick: this.handleCloseModalAndLeave,
          close: this.handleCloseModalAndLeave
        },
        Ops: {
          title: "Ops!",
          cardDescription:
            "Desculpe o transtorno, estamos com problemas para concluir a validação.|Por favor, tente novamente mais tarde.",
          modalIconBind: {
            name: "circle-xmark",
            color: "var(--colors-light-primary-navy-blue)"
          },
          buttonLabel: "Fechar",
          buttonClick: this.handleCloseModalAndContinue,
          close: this.handleCloseModalAndContinue
        }
      };
    },
    modalInfos() {
      if (!this.modalToShow) return null;
      const result = this.modals[this.modalToShow];
      if (result) {
        result.secondButtonClick = this.handleCloseModalAndLeave;
        if (this.modalToShow !== "Ops") {
          result.protocol = this.alreadyRegisteredProtocol;
        }
      }
      return result;
    }
  },
  async mounted() {
    try {
      const data = await this.fetchFaceStatus();
      const { Gerado, Aceito, Processando, Score, Protocolo } = data;
      this.alreadyRegistered = Gerado;
      if (!Gerado) return;
      this.alreadyRegisteredProtocol = Protocolo;

      if (Aceito) {
        this.modalToShow = "SucessfullyRegistereBiometryModal";
        return;
      }
      if (Score > 0) {
        this.modalToShow = "AlreadyRegisteredProtocolLowerModal";
        return;
      }
      if (Score === 0) {
        this.modalToShow = "AlreadyRegisteredProtocolZeroModal";
        return;
      }
      if (Score < 0) {
        this.modalToShow = "AlreadyRegisteredProtocolNegativeModal";
        return;
      }
      if (Processando) {
        this.modalToShow = "AlreadyRegisteredProtocolInProcessModal";
        return;
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
  },
  methods: {
    ...mapActions("biometrics", ["saveFace", "fetchFaceStatus", "authFace", "registerError"]),
    async handleBiometryCameraSuccess(payload) {
      const { encrypted } = payload;

      try {
        this.loadSituation();
        const data = await this.saveFace({ selfie: encrypted });
        const { ImagemAceita, MensagemErro, Protocolo } = data;

        if (!!ImagemAceita && !MensagemErro && !!Protocolo) {
          this.showSucessfullyRegistereBiometryModal = true;
          this.alreadyRegistered = true;
          this.justRegisteredProtocol = Protocolo;
          const waitTime = 1000; // 1s
          this.timer = setInterval(() => this.loadSituation(), waitTime);
          await this.$root.$emit("hide-loading");
        } else {
          this.quitLoadSituationProcess();
          this.$toast({
            description: MensagemErro ?? "Ocorreu um erro ao salvar a biometria",
            status: "error",
            duration: 10000,
            position: "top"
          });
        }
      } catch (error) {
        this.quitLoadSituationProcess();
        this.handleError(error);
      }
    },
    quitLoadSituationProcess() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.process = null;
    },
    async loadSituation() {
      if (this.process === null) {
        this.process = {
          step: 1,
          title: "Validando biometria...",
          description: "Por favor, aguarde. Esta etapa pode levar até 15 segundos."
        };
      } else if (this.process.step > 15) {
        this.quitLoadSituationProcess();
        this.modalToShow = "AlreadyRegisteredProtocolInProcessModal";
      } else {
        this.process.step++;
        if (this.process.step % 5 === 0) {
          try {
            if (this.process.step >= 15 && this.timer) {
              clearInterval(this.timer);
              this.timer = null;
            }
            const data = await this.fetchFaceStatus();
            const { Gerado, Aceito, Processando, Score, Protocolo } = data;
            if (Gerado && !Aceito && this.process.step < 15) {
              return;
            }
            this.alreadyRegistered = Gerado;
            this.alreadyRegisteredProtocol = Protocolo;

            if (!Gerado) {
              this.quitLoadSituationProcess();
              this.modalToShow = "OnErrorModal";
              return;
            }

            if (Aceito) {
              this.quitLoadSituationProcess();
              this.modalToShow = "SucessfullyRegistereBiometryModal";
              return;
            }
            if (Score > 0) {
              this.quitLoadSituationProcess();
              this.modalToShow = "AlreadyRegisteredProtocolLowerModal";
              return;
            }
            if (Score === 0) {
              this.quitLoadSituationProcess();
              this.modalToShow = "AlreadyRegisteredProtocolZeroModal";
              return;
            }
            if (Score < 0) {
              this.quitLoadSituationProcess();
              this.modalToShow = "AlreadyRegisteredProtocolNegativeModal";
              return;
            }
            if (Processando) {
              this.quitLoadSituationProcess();
              this.modalToShow = "AlreadyRegisteredProtocolInProcessModal";
              return;
            }
          } catch (error) {
            window.$log.error("Não foi possível consultar a situação do cadastro da biometria", error);
          }
        } else {
          if (this.process.step === 7) {
            this.process = {
              step: 7,
              title: "Validando biometria...",
              description: "Por favor, aguarde. Estamos concluindo a validação."
            };
          }
        }
      }
    },
    async handleError(error) {
      await this.$root.$emit("hide-loading");
      window.$log.error("biometry page", error);
      this.modalToShow = "Ops";
    },
    async handleResourceError(error) {
      const response = await this.registerError({ cod: "init", dsc: JSON.stringify(error) });
      this.justRegisteredProtocol = response.Protocolo;
      this.modalToShow = "OnErrorModal";
    },
    handleBiometryCameraError(error) {
      window.$log.error("biometry camera", error);
    },
    handleCloseModalAndContinue() {
      if (this.modalToShow === "AlreadyRegisteredProtocolZeroModal") {
        this.alreadyRegistered = false;
      }
      this.modalToShow = "";
    },
    handleCloseModalAndLeave() {
      this.$router.push("/");
    },
    handleCloseModalOnSucessfully() {
      this.$router.push(this.routeBack);
    }
  }
};
</script>

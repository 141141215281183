import Vue from "vue";
import {
  ConfirmarAgendamento,
  Listar,
  ObterPrestadores,
  ObterVoucher,
  ConsultarAgendamento,
  CancelarAgendamento,
  ObterBeneficiarios,
  ObterTiposVacina,
  GerarVoucher,
  ObterDocumento
} from "@/api/voucher";
import { checkUpdatePermission } from "@/utils/checkReadonlyUtil";

const initialState = {
  scheduled: {},
  providers: [],
  selectedCity: "",
  selectedProviderId: "",
  selectedAddressId: "",
  vaccineStep: 1,
  vaccineType: "",
  vaccineTypes: [],
  voucherType: "",
  date: null,
  protocol: "",
  authId: "",
  soliticationDate: new Date(),
  schdeuleDate: new Date(),
  valid: new Date(),
  voucherId: "",
  serviceProviderDesc: "",
  status: "",
  expDate: new Date(),
  vaccineBeneficiaries: []
};

// vuex module
export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  getters: {
    providersByCity: state =>
      state.providers.reduce((map, prov) => {
        if (!map[prov.Cidade]) {
          map = { ...map, [prov.Cidade]: [prov] };
        } else {
          map[prov.Cidade] = [...map[prov.Cidade], prov];
        }
        return map;
      }, {}),
    providersForCity: state => state.providers.filter(p => p.Cidade === state.selectedCity),
    adressForProvider: state =>
      state.providers.filter(p => p.Cidade === state.selectedCity && p.IdPrestadorVoucher === state.selectedProviderId),
    phone: state =>
      state.providers
        .filter(p => p.IdPrestadorVoucher === state.selectedProviderId && p.SrEndereco === state.selectedAddressId)
        .map(p => p.Telefone)
        .pop() || ""
  },
  actions: {
    async init(context) {
      await this.fetchScheduled(context);
      await this.fetchServiceProviders(context);
    },
    async cancelVoucher(context) {
      checkUpdatePermission();
      await CancelarAgendamento({
        agendamento: context.state.authId,
        vinculo: context.rootState.saude.vinculo
      });
    },
    async fetchScheduled(context) {
      const scheduled = await Listar({
        vinculo: context.rootState.saude.vinculo
      });
      if (scheduled && scheduled.length > 0) {
        const s = scheduled.pop();
        await context.commit("SET_SCHEDULED", s);
        await context.commit("SET_AUTH_ID", s.autorizacaoId);
        return true;
      }
      return false;
    },
    async checkSchduled(context) {
      const detail = await ConsultarAgendamento({
        agendamento: context.state.authId
      });

      if (detail) {
        await context.commit("SET_SOLICITATION_DATE", new Date(detail.dataSolicitacao));
        await context.commit("SET_SCHEDULED_DATE", new Date(detail.dataAgendamento));
        await context.commit("SET_VALID", new Date(detail.dataValidadeVoucher));
        await context.commit("SET_STATUS", detail.status);
        const codigoPrestador = Number(detail.codigoPrestador);
        for (let i = 0; i < context.state.providers.length; i++) {
          let provider = context.state.providers[i];
          if (Number(provider.IdPrestadorVoucher) === codigoPrestador) {
            await context.commit("SET_SERVICE_PROVIDER_DESC", provider.NomePrestador);
            break;
          }
        }
      }
    },
    async fetchServiceProviders(context) {
      const providers = await ObterPrestadores({
        vinculo: context.rootState.saude.vinculo
      });

      if (providers.Data) {
        try {
          if (providers.Data.length > 0) {
            for (let i = 0; i < providers.Data.length; i++) {
              let provider = providers.Data[i];
              provider.Cidade = provider.Cidade.trim();
              if (provider.Cidade === "SAO PAULO" || provider.Cidade === "SAO PAULO/SP") {
                provider.Cidade = "SÃO PAULO/SP";
              }
            }
          }
        } finally {
          await context.commit("SET_PROVIDERS", providers.Data);
        }
      }
    },
    async fetchExpDate(context) {
      const voucher = await ObterVoucher({
        vinculo: context.rootState.saude.vinculo
      });
      await context.commit("SET_EXP_DATE", voucher?.Data?.DataExpiracao || new Date());
    },
    async confirm(context) {
      checkUpdatePermission();
      const protocol = await ConfirmarAgendamento({
        vinculo: context.rootState.saude.vinculo,
        dataAgendamento: context.state.date,
        prestador: context.state.selectedProviderId,
        tipoVoucher: context.state.voucherType,
        dataValidade: context.state.expDate,
        endereco: context.state.selectedAddressId
      });
      if (!protocol) return false;
      if (protocol instanceof Error) return false;
      context.commit("SET_PROTOCOL", protocol);
      return true;
    },
    async clear(context) {
      await context.commit("CLEAR");
    },
    async fetchVaccineBeneficiaries(context) {
      if (!context.state.vaccineType) return;
      if (context.state.vaccineBeneficiaries?.length) return;

      const response = await ObterBeneficiarios({
        vinculo: context.rootState.saude.vinculo,
        tipo: context.state.vaccineType
      });
      if (response?.Data) {
        context.commit(
          "SET_VACCINE_BENEFICIARIES",
          response.Data.map(t => ({
            value: t.Sequencia,
            label: t.Nome
          }))
        );
      }
    },
    async fetchVaccineTypes(context) {
      if (context.state.vaccineTypes?.length) return;

      const response = await ObterTiposVacina({
        vinculo: context.rootState.saude.vinculo
      });
      if (response?.Data) {
        context.commit(
          "SET_VACCINE_TYPES",
          response.Data.map(t => ({
            value: t.Codigo,
            label: t.Descricao
          }))
        );
      }
    },
    async createVaccineVoucher(context, { beneficiaries }) {
      if (!context.state.vaccineType) return "";

      const response = await GerarVoucher({
        vinculo: context.rootState.saude.vinculo,
        tipo: context.state.vaccineType,
        beneficiarios: beneficiaries
      });
      if (response?.Data) {
        return ObterDocumento({ key: response.Data });
      } else {
        return "";
      }
    }
  },
  mutations: {
    LOGOUT_USER: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    RESET_CONTEXT: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    CLEAR: state => Object.assign(state, initialState),
    SET_SCHEDULED: (state, next) => Vue.set(state, "scheduled", next),
    SET_PROVIDERS: (state, next) => Vue.set(state, "providers", next),
    SET_SELECTED_CITY: (state, next) => Vue.set(state, "selectedCity", next),
    SET_SELECTED_PROVIDER: (state, next) => Vue.set(state, "selectedProviderId", next),
    SET_SELECTED_ADDRESS: (state, next) => Vue.set(state, "selectedAddressId", next),
    SET_VOUCHER_TYPE: (state, next) => Vue.set(state, "voucherType", next),
    SET_DATE: (state, next) => Vue.set(state, "date", next),
    SET_AUTH_ID: (state, next) => Vue.set(state, "authId", next),
    SET_PROTOCOL: (state, next) => Vue.set(state, "protocol", next),
    SET_SOLICITATION_DATE: (state, next) => Vue.set(state, "soliticationDate", next),
    SET_SCHEDULED_DATE: (state, next) => Vue.set(state, "schdeuleDate", next),
    SET_VALID: (state, next) => Vue.set(state, "valid", next),
    SET_VOUCHER_ID: (state, next) => Vue.set(state, "voucherId", next),
    SET_SERVICE_PROVIDER_DESC: (state, next) => Vue.set(state, "serviceProviderDesc", next),
    SET_STATUS: (state, next) => Vue.set(state, "status", next),
    SET_EXP_DATE: (state, next) => Vue.set(state, "expDate", next),
    SET_VACCINE_STEP: (state, next) => Vue.set(state, "vaccineStep", next),
    SET_VACCINE_TYPE: (state, next) => Vue.set(state, "vaccineType", next),
    SET_VACCINE_TYPES: (state, next) => Vue.set(state, "vaccineTypes", next),
    SET_VACCINE_BENEFICIARIES: (state, next) => Vue.set(state, "vaccineBeneficiaries", next)
  }
};

import * as geoUtils from "@/utils/geoDistance";
import { getStorageData } from "@/utils/storageUtil";
import { obterDadosNavigator } from "@/utils/navigatorUtil";
const baseURL = `${process.env.VUE_APP_SERVER_URL}/MinhaConta`;

export async function AlterarImagemProfile({ vinculo, filename, blobFile }) {
  const data = new FormData();
  data.append("", blobFile, filename);

  return window.$axios
    .post({
      url: `${baseURL}/AlterarImagemProfile`,
      query: {
        vinculo
      },
      contentType: "multipart/form-data",
      data
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("AlterarImagemProfile", error.message);
      return error;
    });
}

export async function AlterarSenha({ senhaAtual, novaSenha, vinculo, autenticacao }) {
  const senhaAtualEncrypted = window.$jse.encrypt(senhaAtual);
  const novaSenhaEncrypted = window.$jse.encrypt(novaSenha);
  const coords: any = await geoUtils.GetLocation().catch(() => null);
  return window.$axios
    .post({
      url: `${baseURL}/AlterarSenha`,
      query: {
        vinculo,
        autenticacao,
        dsUsuario: getStorageData("username", ""),
        lat: coords?.latitude,
        lng: coords?.longitude,
        senhaAtualEncrypted,
        novaSenhaEncrypted
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("AlterarSenha", error.message);
      return error;
    });
}

export async function ObterImagemProfile({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterImagemProfile`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterImagemProfile", error.message);
      return error;
    });
}

export async function ObterAvisos({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterAvisos`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterAvisos", error.message);
      return error;
    });
}

export async function ObterOptin({ login }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterOptin`,
      query: {
        login
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterOptin", error.message);
      return error;
    });
}

export async function AceiteOptinUsuario({ termo, aceite, login }) {
  return window.$axios
    .post({
      url: `${baseURL}/AceiteOptinUsuario`,
      query: {
        termo,
        aceite,
        login
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("AceiteOptinUsuario", error.message);
      return error;
    });
}

export async function ProgramaScreening({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ProgramaScreening`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ProgramaScreening", error.message);
      return error;
    });
}

export async function ProgramaScreeningAceite({ vinculo, login }) {
  return window.$axios
    .post({
      url: `${baseURL}/ProgramaScreeningAceite`,
      query: {
        vinculo,
        login
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ProgramaScreeningAceite", error.message);
      return error;
    });
}

export async function SituacaoSessao({ vinculo }) {
  if (!vinculo) return;

  return window.$axios
    .get({
      url: `${baseURL}/SituacaoSessao`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("SituacaoSessao", error.message);
      return error;
    });
}

export async function EnviarTokenValidacao({ vinculo, telefone, email, id, chave, servico }) {
  return window.$axios
    .post({
      url: `${baseURL}/EnviarTokenValidacao`,
      query: {
        vinculo
      },
      data: {
        telefone,
        email,
        id,
        chave,
        servico
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("EnviarTokenValidacao", error.message);
      return error;
    });
}

export async function InformarCodigoValidacao({ vinculo, telefone, email, id, chave, codigo, autenticacao, servico }) {
  return window.$axios
    .post({
      url: `${baseURL}/InformarCodigoValidacao`,
      query: {
        vinculo
      },
      data: {
        telefone,
        email,
        id,
        chave,
        codigo,
        autenticacao,
        servico
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("InformarCodigoValidacao", error.message);
      return error;
    });
}

export async function PermissaoFuncionalidade({ vinculo, funcionalidade, permissao }) {
  return window.$axios
    .get({
      url: `${baseURL}/PermissaoFuncionalidade`,
      query: {
        vinculo,
        funcionalidade,
        permissao
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("PermissaoFuncionalidade", error.message);
      return error;
    });
}

export async function RegistrarAcesso({ vinculo, servico, funcionalidade, dsUsuario, id }) {
  const coords: any = await geoUtils.GetLocation().catch(() => null);
  return window.$axios
    .post({
      url: `${baseURL}/RegistrarAcesso`,
      query: {
        vinculo
      },
      data: {
        id,
        servico,
        funcionalidade,
        dsUsuario,
        lat: coords?.latitude,
        lng: coords?.longitude
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("RegistrarAcesso", error.message);
      return error;
    });
}

export async function AtualizarVinculo({ vinculo, operacao, perfil, grupo, sistemaOperacional, versaoSO }) {
  let navegador = "";
  let versaoNavegador = "";
  let dsNavegador = "";
  try {
    const dadosNavigator = obterDadosNavigator();
    navegador = dadosNavigator.browser;
    versaoNavegador = dadosNavigator.browserVersion;
    if (!sistemaOperacional || sistemaOperacional === "web") {
      sistemaOperacional = dadosNavigator.os;
      versaoSO = dadosNavigator.osVersion;
    }
  } catch (error) {
    window.$log.error("AtualizarVinculo", error.message);
  }
  if (
    !navegador ||
    navegador === "Unknown" ||
    versaoNavegador === "Unknown" ||
    !sistemaOperacional ||
    sistemaOperacional === "Unknown" ||
    versaoSO === "Unknown"
  ) {
    dsNavegador = navigator.userAgent;
  }
  return window.$axios
    .post({
      url: `${baseURL}/AtualizarVinculo`,
      query: {
        vinculo
      },
      data: {
        operacao,
        perfil,
        grupo,
        sistemaOperacional,
        versaoSO,
        navegador,
        versaoNavegador,
        dsNavegador
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("AtualizarVinculo", error.message);
      return error;
    });
}

export async function ObterBlogPosts({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterBlogPosts`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterBlogPosts", error.message);
      return error;
    });
}

export async function ObtemOptinPendente({ vinculo, template, sistema }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObtemOptinPendente`,
      query: {
        vinculo,
        template,
        sistema
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObtemOptinPendente", error.message);
      return error;
    });
}

export async function VerificarAlteracaoSenhaPendente({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/VerificarAlteracaoSenhaPendente`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("VerificarAlteracaoSenhaPendente", error.message);
      return error;
    });
}

export async function ObterImagem({ vinculo, documento, original }) {
  const key = `${vinculo};${documento};${original ? "T" : "F"}`;
  const chave = `${window.$jse.encrypt(key)}`;
  return await window.$axios
    .get({
      url: `${baseURL}/ObterImagem`,
      query: {
        vinculo,
        chave,
        original
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterImagem", error.message);
      throw new Error(error.message);
    });
}

export async function ObterOnBoardTour({ vinculo, codigo }) {
  return await window.$axios
    .post({
      url: `${baseURL}/ObterOnBoardTour`,
      query: {
        vinculo
      },
      data: {
        codigo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterOnBoardTour", error.message);
      throw new Error(error.message);
    });
}

export async function RespostaOnBoardTour({ vinculo, codigo, id, resposta }) {
  return await window.$axios
    .post({
      url: `${baseURL}/RespostaOnBoardTour`,
      query: {
        vinculo
      },
      data: {
        codigo,
        id,
        resposta
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("RespostaOnBoardTour", error.message);
      throw new Error(error.message);
    });
}

import * as geoUtils from "@/utils/geoDistance";

const baseURL = `${process.env.VUE_APP_SERVER_URL}/FormasContato`;

export async function ObterParametrosEndereco({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterParametrosEndereco`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data.Data)
    .catch(error => {
      window.$log.error("ObterParametrosEndereco", error.message);
      return error;
    });
}

export async function ObterFormasContato({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterFormasContato`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data.Data)
    .catch(error => {
      window.$log.error("ObterFormasContato", error);
      return error;
    });
}

export async function SolicitarAlteracaoGeral({ vinculo, usuario, emails, endereco, telefones, autenticacao }) {
  const coords: any = await geoUtils.GetLocation().catch(() => null);
  return window.$axios
    .post({
      url: `${baseURL}/SolicitarAlteracaoGeral`,
      query: {
        vinculo
      },
      data: {
        usuario,
        emails,
        endereco,
        telefones,
        autenticacao,
        lat: coords?.latitude,
        lng: coords?.longitude
      }
    })
    .then(resp => resp.data.Data)
    .catch(error => {
      window.$log.error("SolicitarAlteracaoGeral", error.message);
      return error;
    });
}

export async function ValidarAlteracaoGeral({ vinculo, emails, endereco, telefones }) {
  return window.$axios
    .post({
      url: `${baseURL}/ValidarAlteracaoGeral`,
      query: {
        vinculo
      },
      data: {
        emails,
        endereco,
        telefones
      }
    })
    .then(resp => resp.data.Data)
    .catch(error => {
      window.$log.error("ValidarAlteracao", error.message);
      return error;
    });
}

export async function ConsultarRequisicaoAlteracao({ chave }) {
  return window.$axios
    .get({
      url: `${baseURL}/ConsultarRequisicaoAlteracao`,
      query: {
        chave
      }
    })
    .then(resp => resp.data.Data)
    .catch(error => {
      window.$log.error("ConsultarRequisicaoAlteracao", error.message);
      return error;
    });
}

export async function ObterMunicipios({ vinculo, uf }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterMunicipios`,
      query: {
        vinculo,
        uf
      }
    })
    .then(resp => resp.data.Data)
    .catch(error => {
      window.$log.error("ObterMunicipios", error.message);
      return error;
    });
}

export function ObterLogradouros({ vinculo, cep }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterLogradouros`,
      query: {
        vinculo,
        cep
      }
    })
    .then(resp => resp.data.Data)
    .catch(error => {
      window.$log.error("ObterLogradouros", error.message);
      return error;
    });
}

export function ObterResumoFormasContato({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterResumoFormasContato`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterResumoFormasContato", error.message);
      return error;
    });
}

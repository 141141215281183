import Vue from "vue";
import * as pa from "../api/primeiroAcesso";

const initialState = {
  term: "<p>Carregando...</p>",
  hash: "",
  vinculos: [{ label: "Saúde - Associado", value: 1 }],
  cpf: "",
  credNumber: "",
  newEmail: "",
  emails: [],
  name: "",
  error: ""
};

// vuex module
export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  actions: {
    async accpetTerms(context) {
      const ret = await pa.AceitarTermo({ hash: context.state.hash });
      return ret && ret.Data;
    },
    async fetchTerms(context, { device }) {
      const terms = await pa.TermoAceiteAtual({ device });

      await context.commit("SET_TERM", terms.Data.Termo);
      await context.commit("SET_HASH", terms.Data.Hash);
    },
    async fetchVinculos(context) {
      let vincs = {};

      if (context.state.cpf !== "") {
        vincs = await pa.ValidarVinculoPorCPF({
          hash: context.state.hash,
          cpf: context.state.cpf
        });
      } else if (context.state.credNumber !== "") {
        vincs = await pa.ValidarVinculo({
          hash: context.state.hash,
          vinculo: context.state.credNumber
        });
      } else {
        return;
      }
      if (vincs && vincs.Data) {
        await context.commit("SET_EMAILS", vincs.Data.Emails);
        if (vincs.Data.PerfilVinculo) {
          await context.commit("SET_NAME", vincs.Data.PerfilVinculo.DescricaoIdentificacaoVinculo);
          await context.commit("SET_CPF", vincs.Data.PerfilVinculo.NumeroDocumento);
        }
        return "";
      } else {
        await context.commit("SET_EMAILS", []);
        await context.commit("SET_NAME", "");
        await context.commit("SET_CPF", "");
        return vincs && vincs instanceof Error ? vincs.message : "Serviço temporariamente indisponível";
      }
    },
    async addEmail(context, { email }) {
      const ret = await pa.CadastrarEmail({
        hash: context.state.hash,
        cpf: context.state.cpf,
        email
      });
      return ret && ret.Data ? "" : ret && ret instanceof Error ? ret.message : "Serviço temporariamente indisponível";
    },
    async checkCode(context, { codigo }) {
      const ret = await pa.InformarConfirmacao({ hash: context.state.hash, codigo });
      return ret && ret.Data ? "" : ret && ret instanceof Error ? ret.message : "Serviço temporariamente indisponível";
    },
    async createUser(context, { senha }) {
      const ret = await pa.CadastrarUsuarioOmint({ hash: context.state.hash, senha });
      return ret && ret.Data ? "" : ret && ret instanceof Error ? ret.message : "Serviço temporariamente indisponível";
    },
    async sendCode(context, { email }) {
      const ret = await pa.EnviarConfirmacao({
        hash: context.state.hash,
        email
      });
      return ret && ret.Data ? "" : ret && ret instanceof Error ? ret.message : "Serviço temporariamente indisponível";
    }
  },
  mutations: {
    LOGOUT_USER: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    RESET_CONTEXT: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    SET_TERM: (state, nextTerm) => {
      Vue.set(state, "term", nextTerm);
    },
    SET_HASH: (state, nextTerm) => {
      Vue.set(state, "hash", nextTerm);
    },
    SET_CPF: (state, nextCPF) => {
      Vue.set(state, "cpf", nextCPF);
    },
    SET_CRED: (state, nextCred) => {
      Vue.set(state, "credNumber", nextCred);
    },
    SET_EMAILS: (state, nextEmails) => {
      Vue.set(state, "emails", nextEmails);
    },
    SET_NAME: (state, nextName) => {
      Vue.set(state, "name", nextName);
    }
  }
};
